import {
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAILURE,
  CREATE_EVENT_EMPTY,
} from "../../../Utils/Constant";

const initialState = {
  data: null,
  main: null,
  id: null,
  loading: false,
  error: null,
};
export const createEventReducer = (state = initialState, action: any) => {

  switch (action.type) {
    case CREATE_EVENT_REQUEST:
      return {
        ...state,
        data: action.payload,
        loading: true,
        error: null,
      };
    case CREATE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        main: action.payload,
        id: action.payload.id,
        error: null,
      };
    case CREATE_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_EVENT_EMPTY:
      return {
        ...state,
        loading: false,
        error: null,
        main: null,
      };
    case "CREATE_EVENT_ID_EMPTY":
      return {
        ...state,
        id: null,
      };
    default:
      return state;
  }
};
