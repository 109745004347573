import React, { useEffect, useRef, useState } from "react";
import { HiLocationMarker } from "react-icons/hi";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Calendar,
  clipboard,
  editEvent,
  deleteIcon,
  Gallery2,
} from "../../Asset";
import { useNavigate } from "react-router-dom";
import { FaCirclePlus } from "react-icons/fa6";
import { deleteEventRequest } from "../../Redux/Actions/Event/DeleteEventActions";
import { useDispatch } from "react-redux";
import { getEventIDReset, getEventRequest } from "../../Redux/Actions";
import ConfirmationModal from "../../modals/ConfirmationModal";
import { formatDate } from "../../Utils/dummyStorage/functions";

const DELETE_CONFIRMATION_MESSAGE =
  "Are you sure you want to delete this event?";

interface PrevEventListProps {
  eventList: object[];
  selectedTab: number;
  button?: boolean;
}
const PrevEventList: React.FC<PrevEventListProps> = ({
  eventList,
  selectedTab,
  button = true,
}) => {
  const dispatch = useDispatch();
  const [listVisible, setListVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [eventToDelete, setEventToDelete] = useState<number | null>(null);
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);

  const handleNavigateToEventForm = (id: number) => {
    // dispatch(getEventIDRequest(id));
    navigate(`/events/form/${id}`, {
      state: {
        isReviewed: true,
        header: "Event Details",
        message:
          "Your invitee will see this, (Read only view of Your Invitation)",
        id: id,
      },
    });
  };

  const handleCreateForm = () => {
    navigate("/events/form/", {
      state: {
        isReviewed: false,
        header: "Create an Event",
        message: "OK, let's create an exciting Invitation!",
      },
    });
  };

  const handleEditEvent = (id: number) => {
    navigate(`/events/form/${id}`, {
      state: {
        isReviewed: false,
        header: "Event Details",
        message:
          "Your invitee will see this, (Edit only view of your Invitation )",
        id: id,
        edited: true,
      },
    });
  };

  const handleReportEventId = (id: number) => {
    navigate(`/report/${id}`, {
      state: {
        id: id,
      },
    });
  };

  const handleDeleteEventId = (id: number) => {
    setEventToDelete(id);
    setDeleteConfirmation(true);
  };

  const confirmDelete = async () => {
    if (eventToDelete !== null) {
      dispatch(deleteEventRequest(eventToDelete));
      setEventToDelete(null);
      setDeleteConfirmation(false);
      setTimeout(() => {
        dispatch(
          getEventRequest(0, 0, selectedTab === 0 ? "UPCOMING" : "PAST")
        );
      }, 500);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setListVisible(false);
        setSelectedIndex(undefined);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      dispatch(getEventIDReset());
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Filter options based on the selected tab
  const filteredOptions =
    selectedTab === 1
      ? pastEventOptions.filter((option) => option.label !== "Edit")
      : pastEventOptions.filter((option) => option.label !== "Gallery");

  return (
    <div className="w-full" style={{ height: "calc(100% - 99px)" }}>
      {eventList.length > 0 ? (
        <div className="w-full h-full pb-8">
          <div className="w-full flex flex-col h-full items-center">
            {!selectedTab && button && (
              <div
                className="cursor-pointer flex items-center my-4"
                onClick={handleCreateForm}
              >
                <FaCirclePlus className="text-[2rem] text-[#0074A1]" />
                &nbsp;&nbsp;
                <span className="text-[#0074A1] block"> Create New Events</span>
              </div>
            )}
            <div className="w-full flex flex-col h-full overflow-auto items-center">
              {eventList.map((item: any, index: number) => {
                return (
                  <div
                    className="md:w-3/4 w-[90%] rounded-xl my-1 p-1 flex items-center shadow border border-grey relative min-w-72 max-w-[45rem]"
                    key={index}
                  >
                    <button
                      className="absolute top-[0.7rem] right-[0.4rem] flex flex-col"
                      onClick={() => {
                        setListVisible((prev) => !prev);
                        setSelectedIndex(index);
                      }}
                    >
                      <BsThreeDotsVertical />
                    </button>
                    {selectedIndex === index && (
                      <div
                        ref={containerRef}
                        className="absolute right-0 bottom-0 rounded-xl border border-grey bg-white "
                      >
                        {listVisible &&
                          filteredOptions.map((item1, index) => {
                            return (
                              <div
                                className={`flex pl-3 py-[1px] gap-1 w-40 z-10 m-1 cursor-pointer border-b border-gray-300 ${item1?.style}`}
                                key={index + "1"}
                                onClick={() => {
                                  if (item1.label === "Edit") {
                                    handleEditEvent(item.id);
                                  }
                                  if (item1.label === "Delete") {
                                    handleDeleteEventId(item.id);
                                  }
                                  if (item1.label === "Report") {
                                    handleReportEventId(item.id);
                                  }
                                }}
                              >
                                <img src={item1.icon} alt="" loading="lazy" />
                                <div className="text-[0.8rem]">
                                  {item1.label}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}

                    <div className="">
                      <img
                        src="https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg"
                        alt="pics"
                        loading="lazy"
                        className="h-16 object-cover rounded-2xl"
                      />
                    </div>
                    <div className="text-left md:mx-2 mx-3 w-full">
                      <div>
                        <span className="text-[0.8rem] text-[#5d5d5d] font-[400]">
                          {formatDate(item.startDate)} {item.startTime}
                        </span>
                      </div>
                      <div
                        className="text-[#024773] cursor-pointer"
                        onClick={() => {
                          handleNavigateToEventForm(item.id);
                        }}
                      >
                        {item.title}
                      </div>
                      <div className="text-[#3c3d47] flex text-[0.8rem] font-[400] w-full items-center">
                        <HiLocationMarker className="text-[1.5rem]" />

                        {item.venueAddress.length > 50
                          ? `${item.venueAddress.slice(0, 50)}..`
                          : item.venueAddress}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full flex flex-col justify-center items-center gap-4">
          {!selectedTab && (
            <div
              className="cursor-pointer flex items-center"
              onClick={handleCreateForm}
            >
              <FaCirclePlus className="text-[2rem] text-[#0074A1]" />
              &nbsp;&nbsp;
              <span className="text-[#0074A1] block"> Create New Events</span>
            </div>
          )}
          <div className=" items-center py-8">
            <img src={Calendar} alt="" loading="lazy" />
            <p className="p-8">
              {" "}
              {!selectedTab ? "No Upcoming Event" : "No Past Event"}
            </p>
          </div>
        </div>
      )}

      {deleteConfirmation && (
        <ConfirmationModal
          onCancel={() => setDeleteConfirmation(false)}
          onConfirm={confirmDelete}
          message={DELETE_CONFIRMATION_MESSAGE}
        />
      )}
    </div>
  );
};

export default PrevEventList;

const pastEventOptions = [
  // {
  //   label: "Report",
  //   icon: clipboard,
  // },
  // {
  //   label: "Gallery",
  //   icon: Gallery2,
  // },
  {
    label: "Edit",
    icon: editEvent,
    style: "h-[1.10rem]",
  },
  {
    label: "Delete",
    icon: deleteIcon,
    style: "h-[1.10rem]",
  },
];
