import { MdLocationSearching } from "react-icons/md";
import React, { useEffect, useRef, useState } from "react";
import { getLocation } from "../../Utils/GoogleLocation";
import axios from "axios";
import List from "../UI/List";
import debounce from "lodash/debounce";

interface FromInputComponentProps {
  label: string;
  name: string;
  setValue?: any;
  mianStyle: string;
  inputStyle: string;
  error: string;
  errorMessage: string;
  labelStyle: string;
  required: boolean | string;
  disabled: boolean;
  type: string;
  register: any;
  locationMessage?: string;
  pickMyLocation?: boolean;
  placeholder?: string;
  value?: any;
}
const FromInputComponent: React.FC<FromInputComponentProps> = ({
  label,
  name,
  setValue,
  mianStyle,
  inputStyle,
  error,
  errorMessage,
  labelStyle,
  required,
  disabled,
  type,
  register,
  locationMessage,
  pickMyLocation,
  placeholder,
}) => {
  const [loading, setLoading] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);
  const [suggestions, setSuggestions] = useState([]);
  const [showList, setShowList] = useState(false);

  const handleSuggestionClick = (data: any) => {
    setShowList(false);
    setValue(name, data?.formattedAddress);
    setValue("latitude", data?.location.latitude);
    setValue("longitude", data?.location?.longitude);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (listRef.current && !listRef.current.contains(event.target as Node)) {
      setShowList(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const debouncedHandleSuggestion = useRef(
    debounce(async (input: any) => {
      setShowList(true);
      try {
        const url = "https://places.googleapis.com/v1/places:searchText";
        const apiKey = "AIzaSyAW9V-0HeVxzUgjWfFZSK8EtUZA4W7oFVE";

        const body = {
          textQuery: input,
        };

        const headers = {
          "Content-Type": "application/json",
          "X-Goog-Api-Key": apiKey,
          "X-Goog-FieldMask": "places.formattedAddress,places.location",
        };
        const response = await axios.post(url, body, { headers });
        setSuggestions(response.data.places);
      } catch (error) {
        setSuggestions([]);
      }
    }, 2000) // Adjust the delay as needed
  ).current;

  const handleInputChange = (value: any) => {
    setLoading(false);
    if (pickMyLocation && value) {
      debouncedHandleSuggestion(value);
    }
  };

  return (
    <div className={`w-full ${mianStyle}`}>
      <p
        className={`${labelStyle} text-left text-[0.9rem] text-[#024773] my-2 font-[400]`}
      >
        {label} {required && <span className={""}>*</span>}
      </p>
      <input
        key={name}
        className={`w-full py-2 bg-white border border-gray-300 rounded shadow active:outline-none focus:outline-none px-2 text-[0.8rem] ${inputStyle} ${
          disabled ? "opacity-50 cursor-not-allowed" : ""
        }`}
        type={type}
        disabled={disabled}
        placeholder={loading ? "Fetching Coordinates..." : placeholder}
        {...register(name, { required: required })}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          setValue(name, value);
          if (pickMyLocation) {
            handleInputChange(value);
          }
        }}
      />
      {pickMyLocation && (
        <div className="text-[0.7rem] text-[#024773] text-left p-1 flex items-center gap-2">
          <span
            className="cursor-pointer flex items-center gap-1"
            onClick={async () => {
              setLoading(true);
              getLocation(setValue);
            }}
          >
            <MdLocationSearching />
            <p>{locationMessage}</p>
          </span>
        </div>
      )}
      {pickMyLocation && showList && (
        <div ref={listRef}>
          <List data={suggestions} handleClick={handleSuggestionClick} />
        </div>
      )}
      {error && (
        <div className="text-[0.7rem] text-left text-red-600">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default FromInputComponent;
