import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { GET_API } from "../../../Services/API/ApiInstance";
import { getEventIDFailure, getEventIDSuccess } from "../../Actions";

function* getEventIDSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    const res = yield call(GET_API, authApi.GET_EVENT_BY_ID + data);
    yield put(getEventIDSuccess(res));
  } catch (error: any) {
    toast.dismiss();
    toast.error(error?.response?.data?.message);
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      setTimeout(() => {
        window.location.href = "/login";
      }, 1000);
    }
    yield put(getEventIDFailure(error));
  }
}

export default getEventIDSaga;
