import React, { useEffect, useState } from "react";
import { HomeLogo } from "../Asset";
import Button from "../Component/AuthButton/Button";
import FormInvities from "../Component/EventForm/FormInvities";
import "../index.css";
import { connect, useDispatch } from "react-redux";
import { inviitationRequest } from "../Redux/Actions";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { baseURL } from "../Services/API/ApiInstance";
import { authApi } from "../Utils/Constant";
import { TailSpin } from "react-loader-spinner";
import { convertStringToDateObject } from "../Utils/dummyStorage/functions";

interface InvitePageProps {
  invitation: any;
}

const InvitePage: React.FC<InvitePageProps> = ({ invitation }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const key = queryParams.get("key");
  const eid = queryParams.get("eid");
  const [data, setData] = useState<any>();
  const [toggle, setToogle] = useState(false);
  const [foodType, setFoodType] = useState("");
  const [invities, setInvities] = useState(1);

  useEffect(() => {
    if (!key || !eid) {
      navigate("/");
    }
    if (key && eid) {
      dispatch(inviitationRequest({ contactId: key, eventId: eid }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, eid]);

  useEffect(() => {
    if (invitation?.data) {
      setData(invitation.data);
      setToogle(invitation.data.isFoodPreferenceEnabled);
      setFoodType(invitation.data.foodPreference);
    }
  }, [invitation?.data, invitation]);

  const handleSubmitForm = async () => {
    if (eid && key && invities && foodType) {
      const requestBody = {
        eventId: eid,
        contactId: key,
        numberOfAttendees: invities,
        foodPreference:
          invitation?.data?.foodPreference === "Strict Vegetarian"
            ? "VEG"
            : "BOTH",
      };

      try {
        const response = await fetch(
          `${baseURL}${authApi.INVITATION_RESPONSE}`,
          {
            method: "POST",
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (response.ok) {
          const data = await response.json();
          toast.success("Success:", data);
        } else {
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      toast.error("Something is Missing...");
    }
  };
  if (invitation.error) {
    return (
      <div className="flex flex-col h-screen bg-[#F4E4E4]">
        <nav className="bg-[#F4E4E4] px-6 md:flex block items-center">
          <img src={HomeLogo} alt="Logo" className="h-16 mr-4" loading="lazy" />
        </nav>
        <div className="flex flex-col justify-center items-center text-center w-full h-full">
          <h1 className="text-6xl font-bold text-red-800 mb-4">404</h1>
          <p className=" text-gray-400 mb-8 font-[400]">
            Oops! The Event you are looking for having some issue.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen">
      <nav className="bg-[#F4E4E4] px-6 md:flex block items-center">
        <img src={HomeLogo} alt="Logo" className="h-16 mr-4" loading="lazy" />
      </nav>
      {!invitation?.loading ? (
        <div className="flex flex-col items-center w-full h-full shadow bg-[#F4E4E4]">
          <p className="text-[#024773] text-[1.5rem] font-bold mb-6">
            Invitation for {data?.title}
          </p>
          <div className="w-[90%] md:w-[65%] xl:w-1/2 h-fit bg-white md:px-16 xl:px-20 px-8 flex flex-col">
            <div className="text-center my-8 text-[#024773] text-[1.2rem] font-bold">
              You are invited to
            </div>
            <div className="mx-auto text-center text-[#989898] text-[0.8rem] font-[400]">
              {`The ${data?.title} on ${convertStringToDateObject(
                data?.startDate
              )} from ${data?.startTime} at ${data?.venueName}`}
              <a
                href={data?.googleMapLink}
                target="_blank"
                className="underline text-blue-500"
              >
                {"(Google Map Link)"}
              </a>
              <div>{data?.venueAddress}</div>
            </div>

            <div className="w-full flex flex-col items-center justify-center">
              <div className="w-full md:w-[80%] my-8">
                <FormInvities
                  sliderClass={true}
                  onchange={setInvities}
                  readOnly={false}
                  messageStyle={"my-2 text-center "}
                  disabled={false}
                  min={0}
                  max={8}
                  defaultVal={0}
                />
              </div>
            </div>
            {toggle && foodType === "Vegetarian & Non Vegetarian" ? (
              <div className="text-[#5d5d5d] w-full flex justify-center font-[400] mb-8">
                <div className="w-max flex flex-col md:w-[80%] text-[#5d5d5d] font-[400] text-[0.8rem] items-center">
                  <div className="flex flex-col items-start w-max gap-2">
                    Food Preference?
                    <label>
                      <input
                        className="text-[0.8rem]"
                        type="radio"
                        name="foodType"
                        disabled={true}
                        // @ts-ignore
                        checked={foodType === "Strict Vegetarian"}
                        value="Strict Vegetarian"
                        onChange={(e) => setFoodType(e.target.value)}
                      />
                      <span className="text-[0.8rem] text-[#969696] mx-2">
                        Strict Vegetarian
                      </span>
                    </label>
                    <label>
                      <input
                        className="text-[0.8rem]"
                        type="radio"
                        name="foodType"
                        disabled={true}
                        checked={foodType === "Vegetarian & Non Vegetarian"}
                        value="Vegetarian & Non Vegetarian"
                        onChange={(e) => setFoodType(e.target.value)}
                      />
                      <span className="text-[0.8rem] text-[#969696] mx-2">
                        Vegetarian & Non Vegetarian
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="w-full flex justify-center mb-8">
              <Button
                lable={"Send your Response"}
                mainStyle="w-72"
                gredient={true}
                buttonClick={handleSubmitForm}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-screen h-screen flex justify-center items-center">
          <TailSpin
            visible={true}
            height="50"
            width="50"
            color="#024773"
            ariaLabel="tail-spin-loading"
            radius="2"
            strokeWidth="2"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    invitation: state.invitationReducer,
  };
};

export default connect(mapStateToProps)(InvitePage);
