import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa6";

interface ReportDDCountProps {
  count: number;
  title: string;
  list: any;
}

const ReportDDCount: React.FC<ReportDDCountProps> = ({
  count,
  title,
  list,
}) => {
  const [showList, setShowList] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);

  const handleList = () => {
    setShowList((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (listRef.current && !listRef.current.contains(event.target as Node)) {
      setShowList(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        ref={listRef}
        className="h-10 w-full flex items-center px-2 my-3 text-[0.9rem] border rounded-lg cursor-pointer"
        onClick={handleList}
      >
        <div className="w-[90%] text-left px-2 truncate">{title}</div>
        <div className="w-[5%] px-10">{count}</div>
        <div className="w-[5%]">
          <FaChevronDown />
        </div>
      </div>
      {showList && (
        <div className="w-full flex gap-2 flex-col justify-center overflow-scroll h-3/4">
          {list.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="flex gap-2 items-center border rounded-lg px-4 py-2"
              >
                <div className="rounded-full overflow-hidden h-9 w-9">
                  <img
                    className="h-full w-full object-cover"
                    src={`${item.contactImageURL}${index}`}
                    alt={item.name}
                    loading="lazy"
                  />
                </div>
                <div>
                  <div className="text-[0.8rem] text-left">{item.name}</div>
                  <div className="text-[0.8rem] font-[400] text-left">
                    {item.mobileNumber}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ReportDDCount;
