import React from "react";
interface ListProps {
  data: any;
  handleClick: (item: any) => void;
}
const List: React.FC<ListProps> = ({ data, handleClick }) => {

  return (
    <div className="w-full max-h-40 overflow-scroll bg-white rounded shadow z-50">
      {data.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className="w-full  truncate bg-white top-6 text-[0.7rem] p-2 my-1 cursor-pointer"
            onClick={() => handleClick(item)}
          >
            {item.formattedAddress}
          </div>
        );
      })}
    </div>
  );
};

export default List;
