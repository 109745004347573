import {
  GET_CONTATCT_REQUEST,
  GET_CONTATCT_SUCCESS,
  GET_CONTATCT_FAILURE,
} from "../../../Utils/Constant";

const initialState = {
  data: {},
  loading: false,
  error: null,
};
export const getContactReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case GET_CONTATCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CONTATCT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case GET_CONTATCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
