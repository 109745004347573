import {
  SAVE_GOOGLE_CONTACT_REQUEST,
  SAVE_GOOGLE_CONTACT_SUCCESS,
  SAVE_GOOGLE_CONTACT_FAILURE,
} from "../../../Utils/Constant";

export const saveAndSendInvitationRequest = (data: object) => {
  return {
    type: SAVE_GOOGLE_CONTACT_REQUEST,
    payload: data,
  };
};

export const saveAndSendInvitationSuccess = (data: object) => {
  return {
    type: SAVE_GOOGLE_CONTACT_SUCCESS,
    payload: data,
  };
};

export const saveAndSendInvitationFailure = (error: object) => {
  return {
    type: SAVE_GOOGLE_CONTACT_FAILURE,
    payload: error,
  };
};
export const saveAndSendInvitationEmpty = () => {
  return {
    type: "SAVE_GOOGLE_CONTACT_EMPTY",
  };
};
