import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { POST_API } from "../../../Services/API/ApiInstance";
import { sendInvitationSuccess, sendInvitationFailure } from "../../Actions";

function* sendInvitationSaga(body: any): Generator<any, void, any> {
  const data = body.payload.data;
  const test = body.payload.test;
  try {
    toast.loading("Sending Invitation...");
    const res = yield call(
      POST_API,
      authApi.SEND_INVITATION + "?isTest=" + test,
      data
    );
    toast.dismiss();
    toast.success(res.message);
    yield put(sendInvitationSuccess(res));
  } catch (error: any) {
    toast.dismiss();
    toast.error(error?.response?.data?.message);
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      setTimeout(() => {
        window.location.href = "/login";
      }, 1000);
    }
    yield put(sendInvitationFailure(error));
  }
}

export default sendInvitationSaga;
