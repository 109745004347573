import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { POST_API } from "../../../Services/API/ApiInstance";
import {
  saveAndSendInvitationSuccess,
  saveAndSendInvitationFailure,
} from "../../Actions";

function* saveAndSendInvitationSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    toast.loading("Saving Invitations...");
    const res = yield call(POST_API, authApi.SAVE_CONTACT, data);
    toast.dismiss();
    toast.success(res.message);
    yield put(saveAndSendInvitationSuccess(res.data));
  } catch (error: any) {
    toast.dismiss();
    toast.error(error?.response?.data?.message);
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      setTimeout(() => {
        window.location.href = "/login";
      }, 1000);
    }
    yield put(saveAndSendInvitationFailure(error));
  }
}

export default saveAndSendInvitationSaga;
