import React, { useEffect, useState } from "react";
import FormHeader from "../../Component/EventForm/FormHeader";
import { useForm } from "react-hook-form";
import Button from "../../Component/AuthButton/Button";
import FormPreference from "../../Component/EventForm/FromPrefernce";
import FormFirst from "../../Component/EventForm/FormFirst";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getEventIDRequest,
  getMainCategoryRequest,
  getSubCategoryRequest,
  postCreateEventRequest,
  validateDateTimeRequest,
} from "../../Redux/Actions";
import FormTestInvitation from "../../Component/EventForm/FormTestInvitation";
import { useLocation, useNavigate } from "react-router-dom";
import {
  updateEventEmpty,
  updateEventRequest,
} from "../../Redux/Actions/Event/UpdateEventActions";
import { TailSpin } from "react-loader-spinner";
import { formHeader } from "../../Utils/dummyStorage/data";
import { postCreateEventEmpty } from "../../Redux/Actions/Form/CreateEventActions";
import { validateDateTimeEmpty } from "../../Redux/Actions/Form/ValidateActions";

type FormInputs = {
  id: number;
  createDate: string;
  updateDate: string;
  title: string;
  category: string;
  subCategory: string;
  venueName: string;
  venueAddress: string;
  googleMapLink: string;
  latitude: string;
  longitude: string;
  startDate: string;
  startTime: string;
  isEndDateEnabled: boolean;
  endDate: string;
  endTime: string;
  isNumberOfInviteesEnabled: boolean;
  isFoodPreferenceEnabled: boolean;
  isDressCodeEnabled: boolean;
  menDressCode: string;
  womenDressCode: string;
};

interface EventFormProps {
  category: any;
  subCategory: any;
  eventList: any;
  createEvent: any;
  updateEvent: any;
  validateTime: any;
}

const EventForm: React.FC<EventFormProps> = ({
  category,
  subCategory,
  eventList,
  createEvent,
  updateEvent,
  validateTime,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isReviewed, header, message, id, edited } = location.state || {};
  const navigate = useNavigate();
  const [form, setForm] = useState(0);
  const [invities, setInvities] = useState(false);
  const [foodPreference, setFoodPreference] = useState(false);
  const [dressCode, setDressCode] = useState(false);
  const [mainList, setMainList] = useState<any>([]);
  const [subList, setSubList] = useState<any>([]);
  const [testInvitation, setTestInvitation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [dressError, setDressError] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      id,
      createDate: "",
      updateDate: "",
      title: "",
      category: "",
      subCategory: "",
      venueName: "",
      venueAddress: "",
      googleMapLink: "",
      latitude: "22.7184975",
      longitude: "75.7184975",
      startDate: "",
      startTime: "",
      isEndDateEnabled: false,
      endDate: "",
      endTime: "",
      isNumberOfInviteesEnabled: false,
      isFoodPreferenceEnabled: false,
      isDressCodeEnabled: false,
      menDressCode: "",
      womenDressCode: "",
    },
  });

  const setFormValues = () => {
    const event = eventList?.data?.event;
    if (event) {
      setValue("createDate", event.createDate);
      setValue("updateDate", event.updateDate);
      setValue("title", event.title);
      setValue("category", event.category);
      setValue("subCategory", event.subCategory);
      setValue("venueName", event.venueName);
      setValue("venueAddress", event.venueAddress);
      setValue("googleMapLink", event.googleMapLink);
      setValue("latitude", "22.7184975");
      setValue("longitude", "75.7184975");
      setValue("startDate", event.startDate);
      setValue("startTime", event.startTime);
      setValue("isEndDateEnabled", event.isEndDateEnabled);
      setValue("endDate", event.endDate);
      setValue("endTime", event.endTime);
      setValue("isFoodPreferenceEnabled", event.isFoodPreferenceEnabled);
      setValue("isDressCodeEnabled", event.isDressCodeEnabled);
      setDressCode(event.isDressCodeEnabled);
      setValue("menDressCode", event.menDressCode);
      setValue("womenDressCode", event.womenDressCode);
    }
  };

  useEffect(() => {
    if (createEvent?.main || updateEvent?.main) {
      setForm(2);
      setTimeout(() => {
        setLoading(false);
      }, 500);
      dispatch(updateEventEmpty());
      dispatch(postCreateEventEmpty());
    }
    if (createEvent?.error || updateEvent?.error) {
      setForm(1);
      setTimeout(() => {
        setLoading(false);
        dispatch(updateEventEmpty());
        dispatch(postCreateEventEmpty());
      }, 2000);
    }
  }, [createEvent, updateEvent]);

  useEffect(() => {
    if (validateTime?.data === true) {
      setForm(1);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
    if (validateTime?.data === false) {
      setForm(0);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [validateTime]);

  useEffect(() => {
    return () => {
      dispatch(updateEventEmpty());
      dispatch(postCreateEventEmpty());
      dispatch(validateDateTimeEmpty());
    };
  }, []);

  useEffect(() => {
    if (id) {
      setValue("id", id);
      setLoading(true);
      dispatch(getEventIDRequest(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (eventList?.statusCode === 200) {
      setFormValues();
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [eventList]);

  useEffect(() => {
    dispatch(getMainCategoryRequest());
  }, [dispatch]);

  useEffect(() => {
    if (category?.data) {
      setMainList(category.data);
    }
    if (subCategory?.data) {
      setSubList(subCategory.data);
    }
  }, [category, subCategory]);

  const handleBack = () => {
    if (form > 0) {
      setForm((prev) => prev - 1);
    } else {
      navigate("/");
    }
  };

  const handleFormButton = () => {
    const body = getValues();
    if (body.venueAddress === "" || body.title === "") {
      return;
    } else {
      setForm((prev) => prev + 1);
    }
  };

  const handleDropdownChange = (data: any, name: string) => {
    if (data.name === "Others" && name === "category") {
      setSubList([{ name: "Other" }]);
      // setValue("subCategory", "Other");
    }
    if (data.name !== "Others" && name === "category" && data.name !== "") {
      setValue("subCategory", "");
      dispatch(getSubCategoryRequest(data.id));
    }
    if (data.name === "Other" && name === "subCategory") {
      // setValue("subCategory", "Other");
    }
  };

  const handleFormSubmit = (data: FormInputs) => {
    if (form === 2) {
      const body = getValues();
      if (isReviewed) {
        return;
      }
      if (edited || createEvent?.id) {
        setLoading(true);
        setTimeout(() => {
          if (createEvent?.id) {
            dispatch(validateDateTimeEmpty());
            dispatch(updateEventRequest({ ...body, id: createEvent?.id }));
          } else {
            dispatch(updateEventRequest({ ...body, id }));
            dispatch(validateDateTimeEmpty());
          }
        }, 1000);
      } else {
        setLoading(true);
        dispatch(validateDateTimeEmpty());
        dispatch(postCreateEventRequest(body));
      }
    }
    if (form === 1) {
      const body = getValues();
      if (isReviewed) {
        return;
      } else {
        setLoading(true);
        dispatch(validateDateTimeRequest(body));
      }
    }
  };

  const renderForm = () => {
    switch (form) {
      case 0:
        return (
          <FormFirst
            invities={invities}
            errors={errors}
            register={register}
            setValue={setValue}
            getValues={getValues}
            handleDropdownChange={handleDropdownChange}
            handleInvities={setInvities}
            mainList={mainList}
            subList={subList}
            isReviewed={isReviewed}
          />
        );
      case 1:
        return (
          <div className="md:w-3/4 lg:w-3/4 xl:w-1/2 w-[90%]">
            <FormPreference
              register={register}
              setValue={setValue}
              getValues={getValues}
              foodPreference={foodPreference}
              dressCode={dressCode}
              setFoodPreference={setFoodPreference}
              setDressCode={setDressCode}
              isReviewed={isReviewed}
              dressError={dressError}
              setDressError={setDressError}
            />
          </div>
        );
      case 2:
        return (
          <FormTestInvitation
            formId={createEvent?.id}
            testInvitation={testInvitation}
            setTestInvitation={setTestInvitation}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="homeScreen md:w-[80vw] w-full right-0 absolute top-12 text-center overflow-clip">
      <div className="h-full w-full flex flex-col items-center justify-center text-center">
        {loading && (
          <div className="flex flex-col justify-center items-center h-screen w-screen absolute">
            <TailSpin
              visible={true}
              height="60"
              width="60"
              color="#024773"
              ariaLabel="tail-spin-loading"
              radius="1"
              strokeWidth="3"
            />
            Loading...
          </div>
        )}
        {error && <div>{error}</div>}
        {!testInvitation && !loading && !error && (
          <FormHeader
            header={header && form === 0 ? header : formHeader[form].header}
            message={message && form === 0 ? message : formHeader[form].message}
            onBack={handleBack}
          />
        )}
        <div className="h-full w-full max-w-[80rem] flex justify-center my-6 overflow-y-scroll overflow-x-hidden">
          <form
            onSubmit={handleSubmit(handleFormSubmit)}
            className="w-full flex flex-col items-center"
          >
            {!loading && !error && renderForm()}
            {!testInvitation && !loading && !error && (
              <div
                className={`md:w-3/4 lg:w-3/4 xl:w-1/2 w-[90%] md:flex flex md:justify-center justify-center ${
                  form === 1 || form === 3 ? " bg-white pb-6" : ""
                }`}
              >
                <div className="w-[90%] flex justify-center">
                  <Button
                    lable={"Next"}
                    mainStyle="w-1/2"
                    type="submit"
                    gredient={true}
                    buttonClick={handleFormButton}
                    // disable={isReviewed && form === 1}
                  />
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    category: state.mainCategoryReducer,
    subCategory: state.subCategoryReducer,
    eventList: state.getEventIDReducer?.data,
    createEvent: state.createEventReducer,
    updateEvent: state.updateEventReducer,
    validateTime: state.ValidateDateReducer,
  };
};

export default connect(mapStateToProps)(EventForm);
