import {
  GET_GALLRY_IMAGE_REQUEST,
  GET_GALLRY_IMAGE_SUCCESS,
  GET_GALLRY_IMAGE_FAILURE,
} from "../../../Utils/Constant";

export const getGalleryImageRequest = (data: object) => {
  return {
    type: GET_GALLRY_IMAGE_REQUEST,
    payload: data,
  };
};

export const getGalleryImageSuccess = (data: object) => {
  return {
    type: GET_GALLRY_IMAGE_SUCCESS,
    payload: data,
  };
};

export const getGalleryImageFailure = (error: object) => {
  return {
    type: GET_GALLRY_IMAGE_FAILURE,
    payload: error,
  };
};
