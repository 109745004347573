import React, { useEffect, useState } from "react";
import HomeCard from "../../Component/Home/HomeCard";
import { emptyGallery, profileCamera } from "../../Asset";
import Button from "../../Component/AuthButton/Button";
import FormHeader from "../../Component/EventForm/FormHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BsShareFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { connect, useDispatch } from "react-redux";
import {
  getGalleryImageRequest,
  uploadGalleryImageRequest,
} from "../../Redux/Actions";
interface ImageObject {
  file: File;
  src: string | ArrayBuffer | null;
}

interface ImageUploaderProps {
  maxImages: number;
}
interface GalleryProps {
  galleryImages: any;
  getImages: any;
}

const Gallery: React.FC<GalleryProps> = ({ galleryImages, getImages }) => {
  const dispatch = useDispatch();
  const location = useParams();
  const eventId = location?.id;
  const [imgArray, setImgArray] = useState<ImageObject[]>([]);
  const [arrayImage, setArrayImage] = useState<any>([{}]);
  const [loading, setLoading] = useState(false);
  const [hide, setHide] = useState(true);
  const navigate = useNavigate();
  const loaction = useLocation();
  // const { id } = loaction.state;

  const getGalleryImages = () => {
    dispatch(getGalleryImageRequest({ id: eventId }));
  };

  useEffect(() => {
    setArrayImage(getImages?.data?.imageUrls);
  }, [getImages]);

  useEffect(() => {
    getGalleryImages();
  }, [galleryImages]);

  const handleBack = () => {
    navigate(-1);
  };

  const [selectedImages, setSelectedImages] = useState<any>([]);

  const toggleImageSelection = (id: any) => {
    const index = selectedImages.indexOf(id);
    if (index === -1) {
      setSelectedImages([...selectedImages, id]);
    } else {
      setSelectedImages(
        selectedImages.filter((imageId: any) => imageId !== id)
      );
    }
  };

  const handleShareImages = () => {
    alert("Selected images shared");
    setSelectedImages([]);
  };

  const handleFileChange = async (e: any) => {
    if (!e.target.files) return;

    const files = Array.from(e.target.files);

    const formData = new FormData();
    files.forEach((file: any, index) => {
      if (file.type.startsWith("image/")) {
        formData.append("images", file);
      }
    });

    dispatch(uploadGalleryImageRequest({ id: eventId, formData }));
  };

  const handleImageRemove = (fileToRemove: File) => {
    const filteredArray = imgArray.filter((img) => img.file !== fileToRemove);
    setImgArray(filteredArray);
  };

  return (
    <div className="homeScreen md:w-[80vw] w-full right-0 absolute top-7 text-center overflow-clip">
      <div className="h-full flex flex-col items-center justify-center w-full text-center">
        <FormHeader header={"Gallery"} message={""} onBack={handleBack} />
        <div className="mb-8"> </div>
        <HomeCard>
          {hide ? (
            <div className="w-full h-full flex flex-col items-center mb-14 ">
              <div className="">
                <p className="text-[0.9rem] my-4 text-[#024773]">
                  Profile Picture
                </p>
                <div className="rounded-md w-32 h-32 overflow-hidden relative ">
                  {false ? (
                    <div className="flex w-full h-full justify-center items-center">
                      <div className="absolute inset-0 flex justify-center items-center z-10">
                        <TailSpin
                          visible={true}
                          height={"8.5rem"}
                          width={"8.5rem"}
                          color="#014774"
                          ariaLabel="tail-spin-loading"
                          radius="2"
                          strokeWidth="2"
                        />
                      </div>
                      <img
                        className="w-full h-full rounded-md object-cover blur-sm"
                        src={emptyGallery}
                        alt="Profile"
                        loading="lazy"
                      />
                    </div>
                  ) : (
                    <img
                      className="w-full h-full rounded-md object-cover"
                      src={emptyGallery}
                      alt="Profile"
                      loading="lazy"
                    />
                  )}
                  <label htmlFor="profilePictureInput">
                    <img
                      title="Change Profile"
                      src={profileCamera}
                      alt=""
                      loading="lazy"
                      className="absolute bottom-0 w-8 right-0 z-0 cursor-pointer  md:z-10"
                    />
                  </label>
                  <input
                    type="file"
                    id="profilePictureInput"
                    className="hidden"
                    accept="image/*"
                    // TODO : add update gallery profile function here
                    onChange={() => true}
                  />
                </div>
              </div>
              <div className="font-bold text-[#024773] text-[1.5rem] mt-6">
                Event Photos
              </div>
              <div className="items-center mb-3 justify-evenly flex">
                <label
                  htmlFor="upload"
                  className="bg-[#0074A1] text-white px-4 py-[0.32rem] mx-2 rounded-lg cursor-pointer transition-all duration-300 w-1/3 min-w-52"
                >
                  <p>Add Photos</p>
                  <input
                    type="file"
                    id="upload"
                    accept="image/png, image/jpeg, image/jpg, image/webp"
                    multiple
                    className="hidden"
                    onChange={handleFileChange}
                  />
                </label>
                <button
                  type="button"
                  className=" bg-[#0074A1] h-fit p-[0.6rem] rounded-lg"
                  onClick={handleShareImages}
                >
                  <BsShareFill className="text-white" />
                </button>
              </div>
              {!galleryImages.loading ? (
                <div className="w-3/4 h-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-1 overflow-scroll mb-6">
                  {arrayImage?.map((item: any, index: number) => (
                    <div key={index} className="relative group">
                      <input
                        type="checkbox"
                        checked={selectedImages.includes(item?.id)}
                        onChange={() => toggleImageSelection(item?.id)}
                        className="absolute top-2 right-2 appearance-none h-4 w-4 checked:bg-[#024773] checked:border-transparent focus:outline-none cursor-pointer"
                      />
                      <span
                        className={`absolute top-2 right-2 h-5 w-5 border border-gray-300 ${
                          selectedImages.includes(item?.id)
                            ? "bg-[#024773]"
                            : ""
                        }`}
                      >
                        {selectedImages.includes(item?.id) && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 mx-auto my-auto text-white"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        )}
                      </span>
                      <img
                        onClick={() => toggleImageSelection(item.id)}
                        src={item?.url}
                        alt=""
                        loading="lazy"
                        className="h-36 w-full object-cover cursor-pointer"
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="w-full flex justify-center">
                  <TailSpin
                    visible={true}
                    height="50"
                    width="50"
                    color="#024773"
                    ariaLabel="tail-spin-loading"
                    radius="2"
                    strokeWidth="2"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="w-full flex flex-col justify-center items-center my-14 ">
              <div>
                <img src={emptyGallery} alt="" loading="lazy" />
              </div>
              <div className="font-bold text-[1.5rem] mt-8 mb-2">
                No added photos
              </div>
              <div className=" text-center font-[400] mb-9">
                Make sure you have added photos of events{" "}
              </div>
              <Button
                mainStyle="w-1/3 min-w-60"
                lable={"Add Photos"}
                gredient={true}
                buttonClick={function (data: object): void {
                  throw new Error("Function not implemented.");
                }}
              />
            </div>
          )}
        </HomeCard>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    galleryImages: state.uploadGalleryImageReducer,
    getImages: state.getGalleryImageReducer,
  };
};

export default connect(mapStateToProps)(Gallery);
