import {
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  UPDATE_EVENT_EMPTY,
} from "../../../Utils/Constant";

export const updateEventRequest = (data: object) => {
  return {
    type: UPDATE_EVENT_REQUEST,
    payload: data,
  };
};

export const updateEventSuccess = (data: object) => {
  return {
    type: UPDATE_EVENT_SUCCESS,
    payload: data,
  };
};

export const updateEventFailure = (error: object) => {
  return {
    type: UPDATE_EVENT_FAILURE,
    payload: error,
  };
};

export const updateEventEmpty = () => {
  return {
    type: UPDATE_EVENT_EMPTY,
  };
};
