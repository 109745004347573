import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { GET_API, POST_API } from "../../../Services/API/ApiInstance";
import {
  uploadGalleryImageSuccess,
  uploadGalleryImageFailure,
  getGalleryImageSuccess,
  getGalleryImageFailure,
} from "../../Actions";

function* getGalleyImageSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    const res = yield call(
      GET_API,
      authApi.GET_GALLERY_IMAGES + `?eventId=${data.id}`
    );
    yield put(getGalleryImageSuccess(res.data));
  } catch (error: any) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      setTimeout(() => {
        window.location.href = "/login";
      }, 1000);
    }
    yield put(getGalleryImageFailure(error));
  }
}

export default getGalleyImageSaga;
