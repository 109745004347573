// import axios from "axios";
// // import { baseURL } from "./ApiConfig";
// export const baseURL = "https://invitepro-stag-apis.up.railway.app/invitepro/";

// let token = sessionStorage.getItem("token") || localStorage.getItem("token");
// const instance = axios.create({
//   baseURL: baseURL,
//   timeout: 10000,
//   headers: {
//     "X-Custom-Header": "foobar",
//     "Content-Type": "application/json",
//     accessToken: token,
//   },
// });

// instance.interceptors.request.use(
//   async (config) => {
//     const accessToken = sessionStorage.getItem("token");
//     if (accessToken) {
//       config.headers["accessToken"] = accessToken;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// export const GET_API = async (path: string, params?: object) => {
//   try {
//     const config = {
//       params: params,
//     };
//     const res = await instance.get(path, config);
//     return res.data;
//   } catch (error) {
//     throw error;
//   }
// };

// export const POST_API = async (path: string, data: object, params?: object) => {
//   try {
//     const config = {
//       params: params,
//     };

//     const res = await instance.post(path, data, config);
//     return res.data;
//   } catch (error) {
//     throw error;
//   }
// };

// export const PUT_API = async (path: string, data: object, params?: object) => {
//   try {
//     const config = {
//       params: params,
//     };
//     const res = await instance.put(path, data, config);
//     return res.data;
//   } catch (error) {
//     throw error;
//   }
// };

// export const DELETE_API = async (path: string, params?: object) => {
//   try {
//     const config = {
//       params: params,
//     };
//     const res = await instance.delete(path, config);
//     return res.data;
//   } catch (error) {
//     throw error;
//   }
// };

// export const PATCH_API = async (
//   path: string,
//   data: object,
//   params?: object
// ) => {
//   try {
//     const config = {
//       params: params,
//     };
//     const res = await instance.patch(path, data);
//     return res.data;
//   } catch (error) {
//     throw error;
//   }
// };

import axios, { AxiosRequestConfig } from "axios";

export const baseURL = "https://invitepro-stag-apis.up.railway.app/invitepro/";

let token = sessionStorage.getItem("token") || localStorage.getItem("token");
const instance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    "X-Custom-Header": "foobar",
    "Content-Type": "application/json",
    accessToken: token,
  },
});

instance.interceptors.request.use(
  async (config) => {
    const accessToken = sessionStorage.getItem("token");
    if (accessToken) {
      config.headers["accessToken"] = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const GET_API = async <T>(path: string, params?: object): Promise<T> => {
  try {
    const config: AxiosRequestConfig = {
      params: params,
    };
    const res = await instance.get<T>(path, config);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const POST_API = async <T>(
  path: string,
  data: object,
  contentType: string = "application/json"
): Promise<T> => {

  try {
    let config: AxiosRequestConfig = {};

    if (contentType === "multipart/form-data") {
      config.headers = {
        ...config.headers,
        "Content-Type": "multipart/form-data",
      };
    }
    const res = await instance.post<T>(path, data, config);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const PUT_API = async <T>(
  path: string,
  data: object,
  params?: object,
  contentType: string = "application/json"
): Promise<T> => {
  try {
    let config: AxiosRequestConfig = {
      params: params,
    };

    if (contentType === "multipart/form-data") {
      config.headers = {
        ...config.headers,
        "Content-Type": "multipart/form-data",
      };
      config.transformRequest = [
        (data) => {
          const formData = new FormData();
          for (let key in data) {
            formData.append(key, data[key]);
          }
          return formData;
        },
      ];
    }

    const res = await instance.put<T>(path, data, config);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const DELETE_API = async <T>(
  path: string,
  params?: object
): Promise<T> => {
  try {
    const config: AxiosRequestConfig = {
      params: params,
    };
    const res = await instance.delete<T>(path, config);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const PATCH_API = async <T>(
  path: string,
  data: object,
  params?: object
): Promise<T> => {
  try {
    const config: AxiosRequestConfig = {
      params: params,
    };
    const res = await instance.patch<T>(path, data, config);
    return res.data;
  } catch (error) {
    throw error;
  }
};
