import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { GET_API } from "../../../Services/API/ApiInstance";
import {
  getTransitionHistoryFailure,
  getTransitionHistorySuccess,
} from "../../Actions";

function* GetCreditHistorySaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    const res = yield call(GET_API, authApi.GET_PURCHASE_HISTORY, data);
    yield put(getTransitionHistorySuccess(res.data));
  } catch (error: any) {
      toast.dismiss();
      toast.error(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        setTimeout(() => {
          window.location.href = "/login";
        }, 1000);
      }
    yield put(getTransitionHistoryFailure(error));
  }
}

export default GetCreditHistorySaga;
