import React, { useEffect, useRef, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
type SelectProps = {
  options: object[];
  name: string;
  errors: any;
  register: any;
  mainStyle: string;
  onChnage: (data: object, name: string) => void;
  label: string;
  labelStyle: string;
  setValue: any;
  getValues?: any;
  index?: number;
  required: boolean | string;
  isReviewed: boolean;
  placeholder?: string;
};

export const SelectComponent: React.FC<SelectProps> = ({
  options,
  name,
  errors,
  register,
  mainStyle,
  label,
  labelStyle,
  setValue,
  onChnage,
  getValues,
  index,
  required,
  isReviewed,
  placeholder,
}) => {
  const [listVisible, setListVisible] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (listRef.current && !listRef.current.contains(event.target as Node)) {
        setListVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   if (getValues("subCategory") !== "Other") {
  //     setShowInput(false);
  //   }
  //   if (getValues("subCategory") === "Other" && !isReviewed) {
  //     setShowInput(true);
  //   }
  // }, [showInput]);

  const handleToggleList = () => {
    setListVisible((prevState) => !prevState);
  };

  const handleOptionClick = (value: string, option: object, name: string) => {
    setListVisible(false);
    onChnage(option, name);
    setValue(name, value);

    // Check if the selected option is "Others" to show input box
    if (value === "Others" || value === "Other") {
      setShowInput(true);
    } else {
      setShowInput(false);
    }
  };

  useEffect(() => {}, [getValues(name)]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setValue(name, e.target.value);
  };

  return (
    <>
      <div ref={listRef} className={`relative w-full h-max my-2 ${mainStyle}`}>
        <p
          className={`${labelStyle} text-left text-[0.9rem] text-[#024773] my-2 font-[400]`}
        >
          {label}
        </p>
        <button
          type="button"
          onClick={handleToggleList}
          className={`w-full px-4 py-2 flex justify-between items-center bg-white rounded shadow text-[0.8rem] ${
            isReviewed ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isReviewed}
        >
          {(showInput && "Other") || getValues(name) || placeholder}
          <FaChevronDown />
        </button>
        {listVisible && (
          <div className="absolute z-50 w-full bg-white border border-gray-200 rounded shadow-lg">
            {options.map((option: any, index: number) => (
              <button
                key={index}
                type="button"
                {...register(name, { required: required })}
                onClick={() => handleOptionClick(option.name, option, name)}
                className="block w-full text-left px-4 py-1 text-[0.8rem] z-50 hover:bg-gray-200"
              >
                {option.name}
              </button>
            ))}
          </div>
        )}
        {showInput && (
          <input
            type="text"
            value={inputValue}
            placeholder="Enter Category"
            onChange={handleInputChange}
            disabled={isReviewed}
            className={`w-full py-2 bg-white border border-gray-300 rounded shadow active:outline-none focus:outline-none px-2 text-[0.8rem] mt-2 ${
              isReviewed ? "opacity-50 cursor-not-allowed" : ""
            }`}
          />
        )}
        {errors[name] && (
          <span className="text-red-500">{errors[name].message}</span>
        )}
      </div>
    </>
  );
};
