import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { GET_API } from "../../../Services/API/ApiInstance";
import {
  validateDateTimeSuccess,
  validateDateTimeFailure,
} from "../../Actions";

function* ValidateDateSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  const { startDate, startTime } = data;

  try {
    const res = yield call(
      GET_API,
      authApi.VALIDATE_DATE_TIME +
        `?startDate=${startDate}` +
        `&startTime=${startTime}`
    );
    if (!res?.data) {
      toast.error(
        "The Start Date & time should be 8 hours after the current date & time"
      );
    }
    yield put(validateDateTimeSuccess(res.data));
  } catch (error: any) {
    toast.dismiss();
    toast.error(error?.response?.data?.message);
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      setTimeout(() => {
        window.location.href = "/login";
      }, 1000);
    }
    yield put(validateDateTimeFailure(error));
  }
}

export default ValidateDateSaga;
