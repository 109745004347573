import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { POST_API } from "../../../Services/API/ApiInstance";
import {
  uploadGalleryImageSuccess,
  uploadGalleryImageFailure,
} from "../../Actions";

function* uploadGalleyImageSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    const res = yield call(
      POST_API,
      authApi.UPLOAD_GALLERY_IMAGES + `?eventId=${data.id}`,
      data.formData,
      "multipart/form-data"
    );
    yield put(uploadGalleryImageSuccess(res.data));
  } catch (error: any) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      setTimeout(() => {
        window.location.href = "/login";
      }, 1000);
    }
    yield put(uploadGalleryImageFailure(error));
  }
}

export default uploadGalleyImageSaga;
