import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { POST_API } from "../../../Services/API/ApiInstance";
import {
  inviitationResponseSuccess,
  inviitationResponseFailure,
} from "../../Actions";

function* invitationResponseSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    const res = yield call(POST_API, authApi.INVITATION_RESPONSE, data);
    toast.success(res.message);
    yield put(inviitationResponseSuccess(res.data));
  } catch (error: any) {
      toast.dismiss();
      toast.error(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        setTimeout(() => {
          window.location.href = "/login";
        }, 1000);
      }
    yield put(inviitationResponseFailure(error));
  }
}

export default invitationResponseSaga;
