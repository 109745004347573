import {
  UPLOAD_GALLRY_IMAGE_REQUEST,
  UPLOAD_GALLRY_IMAGE_SUCCESS,
  UPLOAD_GALLRY_IMAGE_FAILURE,
} from "../../../Utils/Constant";

export const uploadGalleryImageRequest = (data: object) => {
  return {
    type: UPLOAD_GALLRY_IMAGE_REQUEST,
    payload: data,
  };
};

export const uploadGalleryImageSuccess = (data: object) => {
  return {
    type: UPLOAD_GALLRY_IMAGE_SUCCESS,
    payload: data,
  };
};

export const uploadGalleryImageFailure = (error: object) => {
  return {
    type: UPLOAD_GALLRY_IMAGE_FAILURE,
    payload: error,
  };
};
