import React from "react";

const LandingPageText = () => {
  return (
    <div className="flex flex-col justify-center w-full text-center items-center">
      <p className="font-reem font-bold text-[#0074A1] md:mt-0 mt-6 text-[3.5vw] w-full">
        We are glad <br /> you are here!
      </p>
      <div className="md:text-[1.5vw] md:ml-3 text-[2.5vw] mt-3 font-[200] p-2 w-full">
        InvitePro is a great new way to send an invitation and get the
        responses, right away! Get started now.
      </div>
    </div>
  );
};

export default LandingPageText;
