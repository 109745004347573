import React, { useEffect, useState } from "react";
import Button from "../AuthButton/Button";
import RememberMe from "../InputComponent/RememberMe";
import { contact } from "../../Asset";
import { useNavigate, useParams } from "react-router-dom";
import {
  getContactRequest,
  saveAndSendInvitationRequest,
  sendInvitationRequest,
} from "../../Redux/Actions";
import { connect, useDispatch } from "react-redux";
import ContactModal from "../../modals/ContactModal";
import TestInvitationResponse from "./TestInvitationResponse";
import OAuthMain from "../GoogleOAuth/OAuthMain";
import { updateEventEmpty } from "../../Redux/Actions/Event/UpdateEventActions";
interface FormTestInvitationProps {
  contactsData: any;
  testInvitation: boolean;
  setTestInvitation: any;
  formId: number;
  createEvent: any;
}
interface contact {
  fullName: string;
  mobileNumber: string;
}
const FormTestInvitation: React.FC<FormTestInvitationProps> = ({
  contactsData,
  testInvitation,
  setTestInvitation,
  formId,
  createEvent,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [contactArray, setContactArray] = useState([]);
  const [sendInvitation, setSendInvitation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedContact1, setSelectedContact1] = useState<any>();
  const [selectedContact2, setSelectedContact2] = useState<any>();
  const [selectedContactIcon, setSelectedContactIcon] = useState(false);

  // const handleInputChange = (
  //   value: string,
  //   setSelectedContact: Function,
  //   otherSelectedContact: any
  // ) => {
  //   setSelectedContact(value);
  //   setShowModal(true);
  // };
  useEffect(() => {
    if (contactsData?.data.length < 1) {
      setSelectedContact1({});
      setSelectedContact2({});
    }
  }, [contactsData.data]);

  const handleSendInvitation = () => {
    dispatch(
      sendInvitationRequest(
        {
          eventId: id ? id : createEvent?.data?.id,
          contactList: [selectedContact1, selectedContact2],
        },
        true
      )
    );
  };

  useEffect(() => {
    setContactArray(contactsData?.data);
  }, [contactsData?.data]);

  return testInvitation === false ? (
    <div className="homeScreen md:w-[80vw] w-full right-0 absolute top-12 text-center overflow-clip">
      <div className="h-full w-full flex flex-col items-center justify-center">
        <div className="h-full w-full flex justify-center my-6 overflow-scroll">
          <div className="md:w-3/4 w-[90%] lg:w-3/4 xl:w-1/2 h-max md:px-16 max-w-[40rem] px-6 bg-white flex justify-center flex-col items-center pb-16">
            <div className="w-full  py-6 mx-2 text-[#4d4d4d] font-[400] text-[0.8rem] border-b">
              <RememberMe
                label={""}
                checked={sendInvitation}
                onChange={(data) => setSendInvitation(data)}
                message={"Want to test this invitation?"}
                isReviewed={false}
              />
            </div>
            {!sendInvitation ? (
              <>
                <div className="w-full text-[#808080] my-8 font-[400] text-sm">
                  OR
                </div>
                <div className="w-1/2 text-[#808080] my-2 mb-16 font-[400] text-sm">
                  you want to{" "}
                  <span
                    className="underline cursor-pointer"
                    onClick={() =>
                      navigate(
                        `/events/form/${
                          id ? id : createEvent?.data?.id
                        }/contacts`
                      )
                    }
                  >
                    send invitation
                  </span>{" "}
                  <span className="block">directly?</span>
                </div>
              </>
            ) : (
              <div className="w-full flex flex-col items-center justify-center">
                {contactsData.data.length < 1 ? (
                  <div>
                    <div className="w-full mt-1 border border-red-500 rounded p-2 bg-red-100 mb-4">
                      <div className="text-sm text-red-500">
                        Please login with Google to sync your contacts.
                      </div>
                    </div>
                    <OAuthMain isLogin={contactsData.data.length < 1} />
                  </div>
                ) : (
                  <div>
                    <div className="w-full my-5 text-left text-sm text-black flex justify-center">
                      Pick two known contacts and click send to test
                    </div>
                    <OAuthMain isLogin={contactsData.data.length < 1} />
                  </div>
                )}
                <div className="flex items-center w-3/5 p-1  relative">
                  {/* <input
                    type="text"
                    className="border rounded-lg p-2 text-xs w-full h-12"
                    placeholder="Choose Contact 1"
                    value={
                      selectedContact1?.fullName
                        ? selectedContact1?.fullName +
                          "  " +
                          selectedContact1?.mobileNumber
                        : ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e.target.value,
                        setSelectedContact1,
                        selectedContact1
                      )
                    }
                  /> */}
                  <button
                    onClick={() => setShowModal(true)}
                    type="button"
                    className="border flex justify-between items-center rounded-lg p-2 text-xs w-full h-12 text-[#808080]"
                  >
                    <span>
                      {selectedContact1?.fullName
                        ? selectedContact1?.fullName +
                          "  " +
                          selectedContact1?.mobileNumber
                        : "Select Contact 1"}
                    </span>
                    <img
                      loading="lazy"
                      src={contact}
                      alt="Pick Contact"
                      className="h-5 w-5"
                      onClick={() => setSelectedContactIcon(true)}
                    />
                  </button>
                </div>
                <div className="flex flex-col items-center w-3/5">
                  <div className="w-full p-1 relative">
                    {/* <input
                      type="text"
                      className="border rounded-lg p-2 text-xs w-full pr-10 h-12"
                      placeholder="Choose Contact 2"
                      value={
                        selectedContact2?.fullName
                          ? selectedContact2?.fullName +
                            "  " +
                            selectedContact2?.mobileNumber
                          : ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          e.target.value,
                          setSelectedContact2,
                          selectedContact2
                        )
                      }
                    /> */}
                    <button
                      onClick={() => setShowModal(true)}
                      type="button"
                      className="border flex justify-between items-center rounded-lg p-2 text-xs w-full h-12 text-[#808080]"
                    >
                      <span>
                        {selectedContact2?.fullName
                          ? selectedContact2?.fullName +
                            "  " +
                            selectedContact2?.mobileNumber
                          : "Select Contact 2"}
                      </span>
                      <img
                        loading="lazy"
                        src={contact}
                        alt="Pick Contact"
                        className="h-5 w-5"
                        onClick={() => setSelectedContactIcon(false)}
                      />
                    </button>
                    <ContactModal
                      selectedContactIcon={selectedContactIcon}
                      open={showModal}
                      onClose={() => setShowModal(false)}
                      handleSelectedContact1={(contact: any) => {
                        setContactArray(
                          contactsData?.data.filter((existingContact: any) => {
                            return (
                              existingContact.mobileNumber !==
                              contact.mobileNumber
                            );
                          })
                        );
                        setSelectedContact1(contact);
                      }}
                      handleSelectedContact2={(contact: any) => {
                        setContactArray(
                          contactsData?.data.filter((existingContact: any) => {
                            return (
                              existingContact.mobileNumber !==
                              contact.mobileNumber
                            );
                          })
                        );
                        setSelectedContact2(contact);
                      }}
                      contactsData={contactArray}
                      selectedContacts={[selectedContact1, selectedContact2]}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="md:w-full min-w-72 flex justify-center flex-col mt-12">
              <div className="flex justify-center w-full">
                <Button
                  lable={"Yes, I want to test invitation"}
                  gredient={true}
                  mainStyle={`w-full md:w-3/4 max-w-72 ${
                    (selectedContact1 || selectedContact2) && sendInvitation
                      ? ""
                      : "cursor-not-allowed"
                  }`}
                  buttonClick={() => {
                    handleSendInvitation();
                    setTestInvitation(true);
                    navigate(
                      `/events/form/${
                        id ? id : createEvent?.data?.id
                      }/testResponse`
                    );
                  }}
                  disable={
                    (!selectedContact1 && !selectedContact2) || !sendInvitation
                  }
                />
              </div>
              {!sendInvitation && (
                <div
                  className="w-full text-center text-[#4d4d4d] py-2 text-[0.9rem] cursor-pointer"
                  onClick={() => {
                    navigate(
                      `/events/form/${id ? id : createEvent?.data?.id}/contacts`
                    );
                  }}
                >
                  Skip test
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <TestInvitationResponse formId={createEvent?.data?.id} />
  );
};

const mapStateToProps = (state: any) => {
  return {
    contactsData: state.googleContactReducer,
    createEvent: state.createEventReducer,
  };
};

export default connect(mapStateToProps)(FormTestInvitation);
