import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { GET_API } from "../../../Services/API/ApiInstance";
import { getConatctFailure, getConatctSuccess } from "../../Actions";

function* getConatctSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    const res = yield call(GET_API, authApi.GET_CONTACT + `?eventId=${data}`);
    yield put(getConatctSuccess(res.data));
  } catch (error: any) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      setTimeout(() => {
        window.location.href = "/login";
      }, 1000);
    }
    yield put(getConatctFailure(error));
  }
}

export default getConatctSaga;
