import React, { useEffect, useState } from "react";
import HomeCard from "../../Component/Home/HomeCard";
import { Calling, deleteIcon, homecountry, profileCamera } from "../../Asset";
import { connect, useDispatch } from "react-redux";
import { MdDeleteOutline } from "react-icons/md";
import {
  deleteUserAccountRequest,
  deleteUserAccountReset,
  getProfileInfoRequest,
  updateProfileRequest,
} from "../../Redux/Actions";
import ConfirmationModal from "../../modals/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
interface ProfileProps {
  profile: any;
  accountDeleted: object[];
}
const Profile: React.FC<ProfileProps> = ({ profile, accountDeleted }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [profilePic, setProfilePic] = useState(null);

  const getProfileInfo = () => {
    dispatch(getProfileInfoRequest());
  };
  useEffect(() => {
    getProfileInfo();
  }, []);

  const handleDeleteAccount = () => {
    setShowDeleteModal(true);
  };

  useEffect(() => {
    // @ts-ignore
    if (accountDeleted.data && accountDeleted.data.statusCode === 200) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      const tokenExists =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!tokenExists) {
        navigate("/home");
      }
    }
    // @ts-ignore
  }, [showDeleteModal, accountDeleted.data.statusCode]);

  const confirmDeleteAccount = () => {
    dispatch(deleteUserAccountRequest());
    setShowDeleteModal(false);
  };

  const handleProfilePicChange = async (e: any) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("profilePic", file);
    try {
      setLoading(true);
      const accessToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");

      const response = await axios.post(
        "https://invitepro-stag-apis.up.railway.app/invitepro/v1/user/uploadProfilePic",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            accessToken: accessToken,
            accept: "application/json",
          },
        }
      );

      if (response.data.statusCode === 200) {
        getProfileInfo();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error uploading profile picture:", error);
    }
  };

  return (
    <div className="homeScreen md:w-[80vw] w-full right-0 absolute top-12 text-center overflow-clip">
      <div className="h-full w-full flex flex-col items-center">
        <div className="text-[#024773] text-[1.6rem] font-bold w-full mb-3">
          My Profile
        </div>
        <HomeCard>
          <div className="w-full flex flex-col justify-center items-center my-12">
            <div className="relative">
              <div className="rounded-full w-32 h-32 overflow-hidden relative ">
                {profile.loading || loading ? (
                  <div className="flex w-full h-full justify-center items-center">
                    <div className="absolute inset-0 flex justify-center items-center z-10">
                      <TailSpin
                        visible={true}
                        height={"8.5rem"}
                        width={"8.5rem"}
                        color="#014774"
                        ariaLabel="tail-spin-loading"
                        radius="2"
                        strokeWidth="2"
                      />
                    </div>
                    <img
                      className="w-full h-full rounded-full object-cover blur-sm"
                      src={profilePic || profile?.data?.profilePic}
                      alt="Profile"
                      loading="lazy"
                    />
                  </div>
                ) : (
                  <img
                    className="w-full h-full rounded-full object-cover"
                    src={profilePic || profile?.data?.profilePic}
                    alt="Profile"
                    loading="lazy"
                  />
                )}
              </div>
              <label htmlFor="profilePictureInput">
                <img
                  title="Change Profile"
                  src={profileCamera}
                  alt=""
                  loading="lazy"
                  className="absolute bottom-0 w-8 right-0 z-0 cursor-pointer  md:z-10"
                />
              </label>
              <input
                type="file"
                id="profilePictureInput"
                className="hidden"
                accept="image/*"
                onChange={handleProfilePicChange}
              />
            </div>
            <p className="m-3 text-[0.9rem]">{profile?.data?.fullName}</p>
            <div className="w-[80%] md:w-3/4 flex justify-between items-center my-3 max-w-[35rem] min-w-[18rem]">
              <div className="text-left pl-38 w-1/2 text-[0.9rem]">
                <div className="flex gap-3">
                  <img src={Calling} alt="" className="w-4" loading="lazy" />
                  Mobile Number
                </div>
              </div>
              <div className="text-[#7C7C7C] text-right w-1/2 text-[0.8rem]">
                {profile?.data?.countryCode} {profile?.data?.mobileNumber}
              </div>
            </div>
            <div className="w-[80%] md:w-3/4 flex justify-between items-center my-3 max-w-[35rem] min-w-72">
              <div className="text-left w-1/2 text-[0.9rem]">
                <div className="flex gap-3">
                  <img
                    src={homecountry}
                    alt=""
                    className="w-4"
                    loading="lazy"
                  />
                  Country
                </div>
              </div>
              <div className="text-[#7C7C7C] text-right w-1/2 text-[0.9rem]">
                {profile?.data?.countryName}
              </div>
            </div>
            <div className="w-[80%] md:w-3/4 flex justify-between items-center my-3 max-w-[35rem] min-w-72">
              <div className="text-left w-1/2 text-[0.9rem]">
                <div className="flex gap-3">
                  <img src={deleteIcon} alt="" className="w-[1.2rem]" />
                  Delete Account
                </div>
              </div>
              <div className="text-[#7C7C7C] text-right w-1/2 text-[0.9rem]">
                <button
                  className="text-red-500 hover:text-red-700 font-semibold  rounded p-1 px-3"
                  onClick={handleDeleteAccount}
                >
                  Delete Account
                </button>
              </div>
            </div>
          </div>
        </HomeCard>
      </div>
      {showDeleteModal && (
        <ConfirmationModal
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={confirmDeleteAccount}
          message="Are you sure you want to delete your account?"
        />
      )}
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    profile: state.getProfileReducer,
    accountDeleted: state.deleteUserAccountReducer,
  };
};

export default connect(mapStateToProps)(Profile);
