import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Calender,
  Credit,
  Discount,
  Discovery,
  Gallery,
  HomeLogo,
  Logout,
  profile,
  Reports,
  Settings,
  sidebarImage,
} from "../../Asset";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import ConfirmationModal from "../../modals/ConfirmationModal";

const LOGOUT_CONFIRMATION_MESSAGE = "Are you sure you want to log out?";
interface SidebarProps {
  showSidebar: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ showSidebar }) => {
  const [showSetting, setShowSetting] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const showSettinglist = () => {
    setShowSetting((prev) => !prev);
  };

  const handleLogOut = () => {
    setShowLogoutConfirmation(true);
  };

  const confirmLogOut = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    navigate("/home");
  };

  return (
    <>
      {showSidebar && (
        <div
          className="md:w-[20vw] hidden h-screen md:flex md:flex-col md:items-center"
          style={{
            backgroundImage: `url(${sidebarImage})`,
            backgroundSize: "cover, auto",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="h-max pt-6 w-full">
            <img
              loading="lazy"
              src={HomeLogo}
              alt=""
              className="mx-auto mt-3 mb-8"
            />
          </div>
          <div className="w-full">
            {sidebarcontent.map((item, index) => (
              <React.Fragment key={index}>
                {item.name !== "Settings" ? (
                  <Link to={item?.path ? item.path : ""} className="w-full">
                    <div
                      className={`flex items-center pl-[4vw] my-2 py-1.5 gap-2  w-full ${
                        location.pathname === item.path ||
                        // @ts-ignore
                        location.pathname.includes(item?.path2)
                          ? "bg-[#024773]"
                          : ""
                      }`}
                    >
                      <img
                        src={item.icon}
                        alt=""
                        loading="lazy"
                        className={`h-5 ${item.imageStyle}`}
                      />
                      <p className="text-[1.3rem] text-white font-normal">
                        {item.name}
                      </p>
                    </div>
                  </Link>
                ) : (
                  <>
                    <div
                      className="cursor-pointer flex flex-row items-center my-2 py-1.5 gap-1"
                      onClick={showSettinglist}
                    >
                      <img
                        src={item.icon}
                        className="pl-[4vw] h-5"
                        alt=""
                        loading="lazy"
                      />
                      <p className="text-[1.3rem] text-white">{item.name}</p>
                      {showSetting ? (
                        <FaAngleUp className="text-[1.2rem] text-center text-white mt-1 mx-2" />
                      ) : (
                        <FaAngleDown className="text-[1.2rem] text-center text-white mt-1 mx-2" />
                      )}
                    </div>
                    {showSetting &&
                      item.option?.map((item, index) => (
                        <Link to={item.path} key={index} className="w-full">
                          <div
                            className={`flex items-center py-2 px-6 gap-2 ${
                              location.pathname.startsWith(item.path)
                                ? "bg-[#024773]"
                                : ""
                            }`}
                          >
                            <img
                              src={item.icon}
                              alt=""
                              loading="lazy"
                              className={`h-5 pl-[3vw] ${item.imageStyle}`}
                            />
                            <p className="text-[0.9rem] text-white font-normal">
                              {item.name}
                            </p>
                          </div>
                        </Link>
                      ))}
                    {showSetting && (
                      <div
                        className="w-full cursor-pointer"
                        onClick={handleLogOut}
                      >
                        <div
                          className={`flex items-center my-3 mx-6 gap-2 ${
                            // @ts-ignore
                            location.pathname.startsWith(item.path)
                              ? "bg-[#024773]"
                              : ""
                          }`}
                        >
                          <img
                            src={Logout}
                            alt=""
                            loading="lazy"
                            className={`h-5 pl-[3vw] ${item.imageStyle}`}
                          />
                          <p className="text-[0.9rem] text-white font-normal">
                            Log out
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}

      {showLogoutConfirmation && (
        <ConfirmationModal
          onCancel={() => setShowLogoutConfirmation(false)}
          onConfirm={confirmLogOut}
          message={LOGOUT_CONFIRMATION_MESSAGE}
        />
      )}
    </>
  );
};

export default Sidebar;

const sidebarcontent = [
  {
    name: "Events",
    icon: Calender,
    path: "/",
    path2: "/events/form/",
  },
  {
    name: "Report",
    icon: Reports,
    path: "/reportList",
    path2: "/report",
  },
  {
    name: "Gallery",
    icon: Gallery,
    path: "/gallery",
    path2: "/gallery",
    imageStyle: "h-[1rem]",
  },
  {
    name: "Settings",
    icon: Settings,
    path: "/settings",
    option: [
      {
        name: "My Profile",
        icon: profile,
        path: "/profile",
      },
      {
        name: "Buy Credit",
        icon: Discount,
        path: "/buycredit",
      },
      {
        name: "Vendor",
        icon: Discovery,
        path: "/vender",
      },
      {
        name: "My Credit",
        icon: Credit,
        path: "/mycredit",
        imageStyle: "h-4",
      },
    ],
  },
];
