import {
  VALIDATE_DATE_TIME_REQUEST,
  VALIDATE_DATE_TIME_SUCCESS,
  VALIDATE_DATE_TIME_FAILURE,
  VALIDATE_DATE_TIME_EMPTY,
} from "../../../Utils/Constant";

export const validateDateTimeRequest = (data: object) => {
  return {
    type: VALIDATE_DATE_TIME_REQUEST,
    payload: data,
  };
};

export const validateDateTimeSuccess = (data: object) => {
  return {
    type: VALIDATE_DATE_TIME_SUCCESS,
    payload: data,
  };
};

export const validateDateTimeFailure = (error: object) => {
  return {
    type: VALIDATE_DATE_TIME_FAILURE,
    payload: error,
  };
};

export const validateDateTimeEmpty = () => {
  return {
    type: VALIDATE_DATE_TIME_EMPTY,
  };
};
