import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { GET_API, POST_API } from "../../../Services/API/ApiInstance";
import { updateProfileFailure, updateProfileSuccess } from "../../Actions";

function* updateProfileSaga(body: any): Generator<any, void, any> {
  const data = body.payload;
  try {
    const res = yield call(POST_API, authApi.UPDATE_PROFILE, data);
    yield put(updateProfileSuccess(res.data));
  } catch (error: any) {
     toast.dismiss();
     toast.error(error?.response?.data?.message);
     if (error?.response?.status === 401) {
       localStorage.removeItem("token");
       sessionStorage.removeItem("token");
       setTimeout(() => {
         window.location.href = "/login";
       }, 1000);
     }
    yield put(updateProfileFailure(error));
  }
}

export default updateProfileSaga;
