import React from "react";
import { TimePicker } from "react-time-picker-typescript";
import "react-time-picker-typescript/dist/style.css";
import "../../../src/index.css";
import { FaRegClock } from "react-icons/fa6";
import {
  convertTo12hr,
  convertTo24hr,
} from "../../Utils/dummyStorage/functions";
interface FormTimeProps {
  register: any;
  startDate: string | undefined;
  endDate: string | undefined;
  isReviewed: boolean;
  setValue?: any;
  getValues?: any;
}
const FormTime: React.FC<FormTimeProps> = ({
  register,
  startDate,
  endDate,
  isReviewed,
  setValue,
  getValues,
}) => {
  return (
    <div className="w-full my-2">
      <div className="text-left text-[#024773] font-[400]">Time</div>
      <div className="flex justify-between gap-3">
        <div className="relative bg-white w-1/2 rounded">
          <TimePicker
            use12Hours
            name={startDate}
            {...register(startDate)}
            onChange={(value) => {
              setValue(startDate, convertTo24hr(value));
            }}
            disabled={isReviewed}
            value={convertTo12hr(getValues(startDate)) || null}
            placeHolder="Start Time"
            inputClassName={`${
              isReviewed ? "bg-[#FAF2F2]" : "bg-white"
            } w-full text-[0.8rem] p-1 rounded outline-none focus:outline-none disabled:opacity-50`}
          />

          <FaRegClock className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-400 cursor-pointer" />
        </div>
        <div className="relative bg-white w-1/2 rounded">
          <TimePicker
            use12Hours
            name={endDate}
            {...register(endDate)}
            onChange={(value) => {
              setValue(endDate, convertTo24hr(value));
            }}
            disabled={isReviewed}
            value={convertTo12hr(getValues(endDate)) || null}
            placeHolder="End Time"
            inputClassName={`${
              isReviewed ? "bg-[#FAF2F2]" : "bg-white"
            } w-full text-[0.8rem] p-1 rounded outline-none focus:outline-none disabled:opacity-50`}
          />
          <FaRegClock className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-400 cursor-pointer" />
        </div>
      </div>
    </div>
  );
};
export default FormTime;
