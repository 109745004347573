import React, { useState } from "react";
import Button from "../AuthButton/Button";
import { invitationSuccess } from "../../Asset";
import ContactPicker from "./ContactPicker";
import { useNavigate, useParams } from "react-router-dom";
import { sendInvitationEmpty } from "../../Redux/Actions/invitation/sendInvitationActions";
import { useDispatch, useSelector } from "react-redux";
interface TestInvitationResponseProps {
  formId?: number;
}
const TestInvitationResponse: React.FC<TestInvitationResponseProps> = (
  {
    // formId,
  }
) => {
  const formId = useSelector(
    (state: any) => state?.createEventReducer?.data?.id
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [showContactPicker, setShowContactPicker] = useState(false);
  const handleTestInvitation = () => {
    navigate(`/events/form/${id ? id : formId}`, {
      state: {
        isReviewed: false,
        header: "Event Details",
        message:
          "Your invitee will see this, (Edit only view of your Invitation )",
        id: id ? id : formId,
        edited: true,
      },
    });
  };

  return !showContactPicker ? (
    <div className="homeScreen md:w-[80vw] w-full right-0 absolute top-12 text-center overflow-clip">
      <div className="h-full w-full flex flex-col items-center justify-center">
        <div className="h-full w-full flex justify-center my-6 overflow-scroll">
          <div className="md:w-3/4 w-full lg:w-3/4 xl:w-1/2 h-max md:px-16 max-w-[40rem] px-6 flex justify-center flex-col items-center pb-16">
            <div className="w-full py-6 mx-2 text-[#4d4d4d] font-[400] text-[0.8rem] border-b"></div>
            <img
              src={invitationSuccess}
              alt=""
              loading="lazy"
              className="w-24 h-24"
            />
            <div className="w-full text-[#024773] font-[500] text-md mt-8">
              Your test invitation has been
            </div>
            <div className="w-1/2 text-[#024773] font-[500] text-md">
              sent{" "}
              <span className="block">out successfully!! Now look for</span>{" "}
              <span className="block">responses</span>
            </div>
            <div className="md:w-full min-w-72 flex justify-center flex-col mt-4">
              <div className="flex justify-center w-full">
                <Button
                  lable={"Want to make changes for this Invite"}
                  gredient={true}
                  mainStyle="w-full md:w-3/4 max-w-[20rem] truncate"
                  buttonClick={handleTestInvitation}
                />
              </div>
              <div className="flex justify-center w-full">
                <Button
                  lable={"Send the invitation to Others"}
                  gredient={true}
                  mainStyle="w-full md:w-3/4 max-w-[20rem] truncate"
                  buttonClick={() => {
                    setShowContactPicker(true);
                    dispatch(sendInvitationEmpty());
                    navigate(`/events/form/${id ? id : formId}/contacts`);
                  }}
                />
              </div>
            </div>
            <div
              className="w-full text-[#000000B2] font-[500] text-sm leading-[22.5px] cursor-pointer"
              onClick={() => navigate("/")}
            >
              Go to event settings
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <ContactPicker />
  );
};

export default TestInvitationResponse;
