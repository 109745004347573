import React, { useEffect } from "react";
import { FormData } from "./FormData";
import { SelectComponent } from "./FormSelect";
import FromInputComponent from "./FromInputComponent";
import FromCalender from "./FromCalender";
import FormTime from "./FormTime";
import FormInvities from "./FormInvities";
import RememberMe from "../InputComponent/RememberMe";
import { validateDateTimeEmpty } from "../../Redux/Actions/Form/ValidateActions";
import { useDispatch } from "react-redux";
interface FormFirstProps {
  errors: any;
  register: any;
  invities: boolean;
  handleDropdownChange: (data: object, name: string) => void;
  handleInvities: (data: boolean) => void;
  setValue?: any;
  getValues?: any;
  mainList: object[];
  subList: object[];
  isReviewed: boolean;
}
const FormFirst: React.FC<FormFirstProps> = ({
  errors,
  register,
  invities,
  setValue,
  handleDropdownChange,
  handleInvities,
  getValues,
  mainList,
  subList,
  isReviewed,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {}, [
    errors,
    register,
    invities,
    setValue,
    handleDropdownChange,
    handleInvities,
    getValues,
    mainList,
    subList,
    isReviewed,
  ]);

  useEffect(() => {
    return () => {
      dispatch(validateDateTimeEmpty());
    };
  }, []);

  return (
    <div className="w-3/4 md:w-1/2">
      {FormData.map((field, index) => (
        <React.Fragment key={field.name}>
          {field.type === "dropdown" && (
            <SelectComponent
              key={field.name}
              index={index}
              options={index ? subList : mainList}
              name={field.name}
              setValue={setValue}
              errors={errors}
              register={register}
              mainStyle=""
              onChnage={(data, name) => handleDropdownChange(data, name)}
              label={field.label}
              labelStyle={""}
              getValues={getValues}
              required={field.required}
              isReviewed={isReviewed}
              placeholder={field.placeholder}
            />
          )}
          {field.type === "text" && (
            <FromInputComponent
              key={field.name}
              label={field.label}
              register={register}
              name={field.name}
              setValue={setValue}
              mianStyle={""}
              inputStyle={""}
              error={errors[`${field.name}`]}
              errorMessage={errors[field.name]?.message}
              labelStyle={""}
              required={field.required}
              disabled={isReviewed}
              type={field.type}
              pickMyLocation={field?.pickMyLocation}
              locationMessage={field?.locationMessage}
              placeholder={field?.placeholder}
            />
          )}
          {field.type === "date" && (
            <FromCalender
              labelStyle={field.label}
              required={field.required}
              startDate={undefined}
              endDate={undefined}
              setValue={setValue}
              getValues={getValues}
              startName={field.startName}
              endName={field.endDate}
              label={""}
              // @ts-ignore
              startLabel={field.startLabel}
              // @ts-ignore
              endLabel={field.endLabel}
              isReviewed={isReviewed}
              placeholder={field.placeholder}
            />
          )}
          {field.type === "time" && (
            <FormTime
              setValue={setValue}
              startDate={field.startName}
              endDate={field.endDate}
              register={register}
              isReviewed={isReviewed}
              getValues={getValues}
            />
          )}
          {field.type === "slider" && (
            <>
              <RememberMe
                label={""}
                checked={invities}
                onChange={(data) => {
                  handleInvities(data);
                  setValue("isNumberOfInviteesEnabled", data);
                }}
                message={"Want to enable number of invitees?"}
                messageStyle="text-[#024773]"
                isReviewed={isReviewed}
              />
              {invities && (
                <div className="text-[0.7rem] text-left text-[#8B8B8B]">
                  Invitees will see like this
                </div>
              )}
              {invities && (
                <FormInvities disabled={true} min={1} max={8} defaultVal={4} />
              )}
            </>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default FormFirst;
