import React, { useEffect, useState } from "react";
import RememberMe from "../InputComponent/RememberMe";
import { dressCodes, foodPreferences } from "../../Utils/dummyStorage/data";
import { updateEventEmpty } from "../../Redux/Actions/Event/UpdateEventActions";
interface FormPreferenceProps {
  setValue: any;
  register: any;
  getValues: any;
  foodPreference: boolean;
  dressCode: boolean;
  setFoodPreference: any;
  setDressCode: any;
  isReviewed: boolean;
  dressError?: boolean;
  setDressError: any;
}
const FormPreference: React.FC<FormPreferenceProps> = ({
  register,
  setValue,
  getValues,
  foodPreference,
  dressCode,
  setFoodPreference,
  setDressCode,
  isReviewed,
  dressError,
  setDressError,
}) => {
  const [pref, setPref] = useState(getValues("foodPreference"));

  // useEffect(() => {
  //   if (!isReviewed) {
  //     setFoodPreference(false);
  //     setDressCode(false);
  //   }
  //   if (getValues("foodPreference")) {
  //     setFoodPreference(true);
  //   }
  //   // if (getValues("menDressCode") || getValues("womenDressCode")) {
  //   //   setDressCode(true);
  //   // }
  // }, [dressCode, getValues("menDressCode"), getValues("womenDressCode")]);

  return (
    <div className="h-full w-full flex flex-col items-center bg-white mb-8">
      <div className="w-full flex flex-col justify-center items-center mt-12 bg-white overflow-scroll">
        <div className="w-3/4 my-2">
          <RememberMe
            messageStyle="text-[#024773] text-[0.9rem]"
            label={""}
            checked={getValues("isFoodPreferenceEnabled")}
            onChange={(data) => {
              setFoodPreference(data);
              setValue("isFoodPreferenceEnabled", data);
            }}
            message={"Want to enable Food Preference?"}
            isReviewed={isReviewed}
          />
          <div className="text-[#666666] font-[400] text-[0.7rem] max-w-96 text-left">
            If this button is enabled the attendee will be able to pick one -
            Vegetarian, Non-Vegetarian, No preference.
          </div>
        </div>
        <hr className="w-3/4 mx-3 border-t border-gray-300 my-3" />
        <div className="w-3/4 my-2">
          <RememberMe
            messageStyle="text-[#024773] text-[0.9rem]"
            label={""}
            checked={dressCode}
            // checked={getValues("isDressCodeEnabled")}
            onChange={(data) => {
              if (!data) {
                setValue("menDressCode", "");
                setValue("womenDressCode", "");
              }
              setDressCode(data);
              setValue("isDressCodeEnabled", data);
            }}
            message={"Want to enable Dress Code?"}
            isReviewed={isReviewed}
          />
          {dressCode &&
            dressCodes.map((item, index) => (
              <div key={index}>
                <p className="text-[0.8rem] font-[400] text-[#0074A1] text-left mt-6">
                  {item.lable}
                </p>
                <div
                  className={`w-full h-[15vh] ${
                    dressError ? "border border-red-600" : "border"
                  }  rounded-lg p-1 relative`}
                >
                  {isReviewed ? (
                    <textarea
                      className={`w-full h-[98%] resize-none active:outline-none focus:outline-none outline-none ${
                        !dressError
                          ? "placeholder:text-[#666666]"
                          : "placeholder:text-red-600"
                      } placeholder:font-[400] placeholder:text-[0.7rem] text-[0.8rem] pl-2 pt-2`}
                      name={`dressCode${index}`}
                      {...register(item.name)}
                      id={`dressCode${index}`}
                      placeholder={
                        index !== 0
                          ? "Example: Casual, Saree etc."
                          : "Example: Casual, Normal, Formal, Indian Traditional etc."
                      }
                      defaultValue={getValues(item.name)}
                      disabled={isReviewed}
                    />
                  ) : (
                    <textarea
                      className={`w-full h-[98%] resize-none active:outline-none focus:outline-none outline-none ${
                        !dressError
                          ? "placeholder:text-[#666666]"
                          : "placeholder:text-red-600"
                      } placeholder:text-[0.7rem] placeholder:font-[400] text-[0.8rem] pl-2 pt-2`}
                      name={`dressCode${index}`}
                      {...register(item.name)}
                      id={`dressCode${index}`}
                      placeholder={
                        index !== 0
                          ? "Example: Casual, Saree etc."
                          : "Example: Casual, Normal, Formal, Indian Traditional etc."
                      }
                    />
                  )}
                  <p
                    className={`absolute bottom-1 text-center w-full ${
                      !dressError ? "text-[#666666]" : "text-red-600"
                    }  text-[0.8rem] font-[400]`}
                  >
                    100 characters
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FormPreference;
