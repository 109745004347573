import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineSmsFailed } from "react-icons/md";
interface ContactModalProps {
  selectedContactIcon: boolean;
  open: boolean;
  onClose: () => void;
  handleSelectedContact1: (contact: object) => void;
  handleSelectedContact2: (contact: object) => void;
  contactsData: any;
  selectedContacts: string[];
}

const ContactModal: React.FC<ContactModalProps> = ({
  selectedContactIcon,
  open,
  onClose,
  handleSelectedContact1,
  handleSelectedContact2,
  contactsData = [],
  selectedContacts,
}) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!open) return;
      const modal = document.getElementById("contact-modal");
      if (modal && !modal.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  if (!open) return null;

  const handleContactSelect = (contact: any) => {
    if (selectedContactIcon) {
      handleSelectedContact1(contact);
    } else {
      handleSelectedContact2(contact);
    }
    onClose();
  };

  return (
    <div className="fixed md:pl-[20vw] w-screen inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-30"></div>
      <div
        id="contact-modal"
        className="bg-white rounded-lg p-6 w-96 z-50 relative h-96 mt-18 overflow-hidden"
      >
        <button
          className="absolute top-4 right-4 text-gred-500 hover:text-gray-600"
          onClick={onClose}
        >
          <AiOutlineClose size={20} />
        </button>
        <h2 className="text-md font-bold">Choose a contact</h2>
        <div className="w-full flex gap-1 flex-col justify-center overflow-scroll">
          <div className="h-[320px] overflow-x-auto ">
            {contactsData.map((contact: any, index: number) => {
              return (
                <div
                  key={index}
                  className="cursor-pointer m-1 hover:bg-gray-100 flex items-center justify-between  border p-1 rounded-xl border-gray-200"
                  onClick={() => {
                    handleContactSelect({
                      fullName: contact.fullName,
                      mobileNumber: contact.mobileNumber,
                    });
                  }}
                >
                  <div className="flex items-center">
                    <div className="rounded-full overflow-hidden h-9 w-9">
                      <img
                        loading="lazy"
                        className="h-full w-full object-cover"
                        src={contact.url}
                        alt={contact.fullName}
                      />
                    </div>
                    <div className="mx-2">
                      <div className="flex-1">
                        <div className="text-left text-sm">
                          {contact.fullName}
                        </div>
                        <div className="text-sm text-gray-500 text-left">
                          {contact.mobileNumber}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {contactsData.length < 1 && (
              <div className=" w-full h-full flex justify-center items-center text-sm text-gray-400">
                <MdOutlineSmsFailed className="w-12 text-[1.5rem]" /> No Contact
                Found
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
