export function convertDate(date: Date | null | undefined): string | null {
  if (!date) {
    return null;
  }

  // Convert to local timezone
  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  // Formatting the resulting date to "YYYY-MM-DD" format
  const convertedDate: string = localDate.toISOString().split("T")[0];

  return convertedDate;
}

export function convertStringToDateObject(dateString: string): Date | null {
  if (!dateString) {
    return null;
  }
  // Split the date string into year, month, and day parts
  const [yearStr, monthStr, dayStr] = dateString.split("-");
  const year = parseInt(yearStr, 10);
  const month = parseInt(monthStr, 10) - 1; // Month is zero-based
  const day = parseInt(dayStr, 10);

  // Create a new Date object
  const dateObject = new Date(year, month, day);

  return dateObject;
}

export const formatDate = (dateString: string) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  // @ts-ignore
  const formattedDate = new Date(dateString).toLocaleDateString(
    "en-GB",
    // @ts-ignore
    options
  );
  const dayIndex = formattedDate.indexOf(" ");
  const monthIndex = formattedDate.lastIndexOf(" ");
  return (
    formattedDate.slice(0, dayIndex) +
    "," +
    formattedDate.slice(dayIndex, monthIndex) +
    "," +
    formattedDate.slice(monthIndex)
  );
};

export function convertTo24hr(time12: any) {
  // Extract hours, minutes, and period (AM/PM) from the input time string
  var timePart = time12.split(" ");
  var time = timePart[0];
  var period = timePart[1];

  var hours = parseInt(time.split(":")[0]);
  var minutes = parseInt(time.split(":")[1]);

  // Convert to 24-hour format
  if (period === "AM" && hours === 12) {
    hours = 0; // Midnight case
  } else if (period === "PM" && hours !== 12) {
    hours += 12; // PM time
  }

  // Format the time in 24-hour format (HH:mm)
  var time24 = ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2);
  return time24;
}

export function convertTo12hr(time24: any) {
  if (!time24) {
    return "";
  }
  var hours = parseInt(time24.split(":")[0]);
  var minutes = parseInt(time24.split(":")[1]);

  var period = hours >= 12 ? "PM" : "AM";

  var hours12 = hours % 12 === 0 ? 12 : hours % 12;

  var time12 =
    ("0" + hours12).slice(-2) + ":" + ("0" + minutes).slice(-2) + " " + period;
  return time12;
}
