import {
  VALIDATE_DATE_TIME_REQUEST,
  VALIDATE_DATE_TIME_SUCCESS,
  VALIDATE_DATE_TIME_FAILURE,
  VALIDATE_DATE_TIME_EMPTY,
} from "../../../Utils/Constant";

const initialState = {
  data: {},
  loading: false,
  error: null,
};
export const ValidateDateReducer = (state = initialState, action: any) => {

  switch (action.type) {
    case VALIDATE_DATE_TIME_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VALIDATE_DATE_TIME_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case VALIDATE_DATE_TIME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case VALIDATE_DATE_TIME_EMPTY:
      return {
        ...state,
        loading: false,
        error: null,
        data: null,
      };
    default:
      return state;
  }
};
