import {
  GET_CONTATCT_REQUEST,
  GET_CONTATCT_SUCCESS,
  GET_CONTATCT_FAILURE,
} from "../../../Utils/Constant";

export const getConatctRequest = (data: any) => {
  return {
    type: GET_CONTATCT_REQUEST,
    payload: data,
  };
};

export const getConatctSuccess = (data: object) => {
  return {
    type: GET_CONTATCT_SUCCESS,
    payload: data,
  };
};

export const getConatctFailure = (error: object) => {
  return {
    type: GET_CONTATCT_FAILURE,
    payload: error,
  };
};
