import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Button from "../Component/AuthButton/Button";

interface Contact {
  fullName: string;
  mobileNumber: string;
  url: string;
}

interface SelectedContactProps {
  open: boolean;
  onClose: () => void;
  contactsData: Contact[];
  selectedContacts: Contact[];
  setSelectedContacts: any;
}

const SelectedContact: React.FC<SelectedContactProps> = ({
  open,
  onClose,
  contactsData,
  selectedContacts,
  setSelectedContacts,
}) => {
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [tempContact, setTempContact] = useState<Array<Contact>>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCount, setSelectedCount] = useState(selectedContacts.length);

  useEffect(() => {
    if (selectedContacts.length === 0) {
      setSelectAll(false);
      setSelectedCount(selectedContacts.length);
      setSelectedIndex([]);
    } else if (selectedContacts.length === contactsData.length) {
      setSelectAll(true);
      setSelectedCount(selectedContacts.length);
      // @ts-ignore
      setSelectedIndex([].fill(true));
    } else {
      setSelectAll(false);
      setSelectedCount(selectedContacts.length);
    }
  }, [selectedContacts, contactsData]);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (selectAll) {
      setSelectedCount(0);
      setTempContact([]);
      setSelectedContacts([]);
    } else {
      setSelectedCount(contactsData.length);
      setTempContact(contactsData);
    }
  };

  const toggleSelectContact = (contact: Contact, index: number) => {
    const isSelected = tempContact.some(
      (selectedContact) =>
        selectedContact.fullName === contact.fullName &&
        selectedContact.mobileNumber === contact.mobileNumber
    );

    if (isSelected) {
      setTempContact((prev) => {
        return prev.filter(
          (selectedContact) =>
            selectedContact.fullName !== contact.fullName ||
            selectedContact.mobileNumber !== contact.mobileNumber
        );
      });
      const updatedContacts = selectedContacts.filter(
        (selectedContact) =>
          selectedContact.fullName !== contact.fullName ||
          selectedContact.mobileNumber !== contact.mobileNumber
      );
      setSelectedContacts(updatedContacts);
      setSelectedCount(updatedContacts.length);
    } else {
      // @ts-ignore
      setSelectedIndex((prev: any) => {
        return [...prev, index];
      });
      setTempContact((prev) => {
        return [...prev, contact];
      });
      setSelectedContacts((prev: any) => {
        return [...prev, contact];
      });
      setSelectedCount(selectedCount + 1);
    }
  };

  const handleSelect = () => {
    setSelectedContacts(tempContact);
    onClose();
  };

  if (!open || !contactsData) return null;

  return (
    <div className="fixed md:pl-[20vw] w-screen inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-30"></div>
      <div
        id="contact-modal"
        className="bg-white rounded-lg p-6 md:w-[30rem] w-[90%] min-w-60 z-50 relative mt-18 overflow-hidden"
      >
        <button
          className="absolute top-4 right-4 text-gred-500 hover:text-gray-600"
          onClick={onClose}
        >
          <AiOutlineClose size={20} />
        </button>
        <h2 className="text-md font-bold">Choose a contact</h2>
        <div className="w-full flex gap-1 flex-col justify-center overflow-scroll">
          <div className="flex my-2 border-b borde-grey-500 py-2 align-middle items-center cursor-pointer">
            <input
              type="checkbox"
              name=""
              id=""
              checked={selectAll}
              onChange={toggleSelectAll}
            />
            <div className="text-sm mx-2">
              Select All ({selectedCount}/{contactsData.length})
            </div>
          </div>
          <div className="h-[300px] overflow-x-auto">
            {contactsData.map((contact: Contact, index: number) => (
              <div className="flex gap-1 w-full" key={index}>
                <input
                  key={index}
                  type="checkbox"
                  name=""
                  id=""
                  // @ts-ignore
                  checked={
                    selectAll ||
                    // @ts-ignore
                    selectedIndex.includes(index) ||
                    selectedContacts.includes(contact)
                  }
                  onChange={() => toggleSelectContact(contact, index)}
                />
                <div className="cursor-pointer w-full m-1 hover:bg-gray-100 flex items-center justify-between  border p-1 rounded-lg border-gray-200">
                  <div className="flex items-center">
                    <div className="rounded-full mx-2 overflow-hidden h-[2.30rem] w-[2.30rem]">
                      <img
                        loading="lazy"
                        className="h-full w-full object-cover"
                        src={contact.url}
                        alt={contact.fullName}
                      />
                    </div>
                    <div className="mx-2">
                      <div className="text-left text-sm">
                        {contact.fullName}
                      </div>
                      <div className="text-sm text-gray-500 text-left">
                        {contact.mobileNumber}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Button lable={"Select"} gredient={true} buttonClick={handleSelect} />
        </div>
      </div>
    </div>
  );
};

export default SelectedContact;
