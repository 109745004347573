import { useEffect, useRef, useState } from "react";
import HomeCard from "../../Component/Home/HomeCard";
import { useDispatch } from "react-redux";
import {
  getEventRequest,
  getEventReset,
  getProfileInfoRequest,
} from "../../Redux/Actions";
import { connect } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { sendInvitationEmpty } from "../../Redux/Actions/invitation/sendInvitationActions";
import { postCreateEventIDEmpty } from "../../Redux/Actions/Form/CreateEventActions";
import { saveAndSendInvitationEmpty } from "../../Redux/Actions/invitation/saveAndSendInvitationActions";
import { formatDate } from "../../Utils/dummyStorage/functions";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { clipboard } from "../../Asset";
import { useNavigate } from "react-router-dom";

interface EventHomeProps {
  getEventData: any;
}

const ReportsList: React.FC<EventHomeProps> = ({ getEventData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listVisible, setListVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const containerRef = useRef<HTMLDivElement>(null);

  const fetchData = async () => {
    dispatch(getEventRequest(0, 0, "UPCOMING"));
  };
  useEffect(() => {
    fetchData();
    return () => {
      dispatch(getEventReset());
    };
  }, []);

  const getProfileInfo = () => {
    dispatch(getProfileInfoRequest());
  };
  useEffect(() => {
    dispatch(sendInvitationEmpty());
    // dispatch(postCreateEventIDEmpty());
    dispatch(sendInvitationEmpty());
    dispatch(saveAndSendInvitationEmpty());
    getProfileInfo();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setListVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleReportEventId = (id: number) => {
    navigate(`/report/${id}`);
  };

  const eventList =
    getEventData?.data && getEventData?.data?.events
      ? getEventData?.data?.events
      : [];
  return (
    <div className="homeScreen md:w-[80vw] w-full right-0 absolute top-12 text-center overflow-clip">
      <div className="h-full w-full flex flex-col items-center">
        <div className="text-[#024773] text-[1.6rem] font-bold w-full mb-8">
          Reports
        </div>
        <HomeCard>
          <div className="w-full mt-3 h-full">
            {getEventData?.loading ? (
              <div className="w-full flex justify-center">
                <TailSpin
                  visible={true}
                  height="50"
                  width="50"
                  color="#024773"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  strokeWidth="2"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <div className="w-full flex flex-col h-full overflow-auto items-center">
                {eventList.map((item: any, index: number) => {
                  return (
                    <div
                      className="md:w-3/4 w-[90%] rounded-xl my-1 p-1 flex items-center shadow border border-grey relative min-w-72 max-w-[45rem] cursor-pointer"
                      key={index}
                      onClick={() => {
                        handleReportEventId(item.id);
                      }}
                    >
                      <div className="">
                        <img
                          src="https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?cs=srgb&dl=pexels-wendywei-1190298.jpg&fm=jpg"
                          alt="pics"
                          loading="lazy"
                          className="h-16 object-cover rounded-2xl"
                        />
                      </div>
                      <div className="text-left md:mx-2 mx-3 w-full">
                        <div>
                          <span className="text-[0.8rem] text-[#5d5d5d] font-[400]">
                            {formatDate(item.startDate)} {item.startTime}
                          </span>
                        </div>
                        <div className="text-[#024773] cursor-pointer">
                          {item.title}
                        </div>
                        <div className="text-[#3c3d47] flex text-[0.8rem] font-[400] w-full items-center">
                          <HiLocationMarker className="text-[1.5rem]" />

                          {item.venueAddress.length > 50
                            ? `${item.venueAddress.slice(0, 50)}..`
                            : item.venueAddress}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </HomeCard>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getEventData: state.getEventReducer,
  };
};

export default connect(mapStateToProps)(ReportsList);
const filteredOptions = [
  {
    label: "Report",
    icon: clipboard,
  },
];
