import React, { useEffect, useState, useCallback } from "react";
import FormHeader from "../../Component/EventForm/FormHeader";
import { warning } from "../../Asset";
import Button from "../../Component/AuthButton/Button";
import { connect, useDispatch } from "react-redux";
import {
  getCreditPlanRequest,
  updateCreditPlanRequest,
} from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import useRazorpay from "react-razorpay";
import axios from "axios";
import { POST_API } from "../../Services/API/ApiInstance";
import toast from "react-hot-toast";
import { TailSpin } from "react-loader-spinner";

interface BuyCreditProps {
  plans: any;
  updatePlan: any;
  profile: any;
}

const BuyCredit: React.FC<BuyCreditProps> = ({
  plans,
  updatePlan,
  profile,
}) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState<any>();
  const navigate = useNavigate();
  const [Razorpay, isLoaded] = useRazorpay();

  const handlePayment = useCallback(async () => {
    try {
      const response = await POST_API("/v1/credit/createOrder", {
        planId: selectedOption.id,
        isProdEnv: false,
      });
      // @ts-ignore
      const order = response?.data;
      const options = {
        key: "rzp_test_sHR4hGyewKVrCU",
        amount: order.amount,
        currency: "INR",
        order_id: order.order_id,
        name: "Invite Pro",
        handler: (res: any) => {
          dispatch(
            updateCreditPlanRequest({
              isProdEnv: false,
              orderId: res?.razorpay_order_id,
              paymentId: res?.razorpay_payment_id,
              signature: res?.razorpay_signature,
            })
          );
        },
        prefill: {
          contact: "9876543210",
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    } catch (error) {
      console.error("Error creating order:", error);
    }
  }, [Razorpay, selectedOption]);

  useEffect(() => {
    if (isLoaded && selectedOption) {
      handlePayment();
    }
  }, [isLoaded, handlePayment, selectedOption]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleOptionChange = (e: any) => {
    const selectedPlan = plans?.data.find(
      (plan: any) => plan.name === e.target.value
    );
    setSelectedOption(selectedPlan);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Send the selected option to the API
    // Reset selected option state if needed
    setSelectedOption(null);
  };

  useEffect(() => {
    dispatch(getCreditPlanRequest());
  }, [dispatch]);

  return (
    <div className="homeScreen md:w-[80vw] w-full right-0 absolute top-12 text-center overflow-clip">
      <div className="h-full w-full flex flex-col items-center justify-center">
        <FormHeader header={"Buy Credits"} message={""} onBack={handleBack} />
        <div className="h-full w-full flex justify-center my-6 overflow-scroll">
          <div className="md:w-3/4 w-[90%] lg:w-3/4 xl:w-1/2 h-max md:px-16 max-w-[40rem] px-6 bg-white flex justify-center flex-col items-center pb-16">
            <div className="w-full py-6 mx-2 text-[#4d4d4d] font-[400] text-[0.9rem] border-b">
              Please review the following options that suits your need
            </div>
            <div className="w-full mt-4 text-[0.9rem] text-left">
              Want to send this invitation to more than 15 contacts?
            </div>
            <div className="w-full py-1 text-[#4d4d4d] text-[0.9rem] text-left font-[400]">
              You can use these credits for lifetime.
            </div>

            {!plans?.loading ? (
              <form onSubmit={handleSubmit} className="w-full">
                {Array.isArray(plans?.data) &&
                  plans?.data.map((item: any, index: any) => (
                    <div
                      key={index}
                      className={`flex m-2 p-[2px] ${
                        selectedOption?.name === item.name
                          ? "bg-[#0074A191] rounded max-w-[12rem] h-[23px]"
                          : ""
                      }`}
                    >
                      <input
                        type="radio"
                        id={item.id}
                        name="creditOption"
                        value={item.name}
                        checked={selectedOption?.name === item.name}
                        onChange={handleOptionChange}
                        className="mr-4 "
                      />
                      <label
                        htmlFor={`option-${index}`}
                        className="text-[#1e1e1e] text-[0.8rem] text-left font-[400]"
                      >
                        {item.name} - Rs.{item.amountInRupees}
                      </label>
                    </div>
                  ))}
                <div className="w-full flex justify-center text-[#4d4d4d] text-[0.8rem] text-left font-[400] py-8">
                  <div className="bg-[#f4f4f4] flex items-center py-2 px-4 rounded-lg gap-2">
                    <img src={warning} alt="" className="w-5" loading="lazy" />
                    <p className="text-[#494949] text-[0.8rem] text-center">
                      All unused credit will be in your account for reuse
                    </p>
                  </div>
                </div>
                <div className="md:w-full min-w-72 flex justify-center flex-col">
                  <div className="flex justify-center w-full">
                    <Button
                      lable={"Proceed to Buy"}
                      gredient={true}
                      mainStyle={`max-w-[18rem] w-72 ${
                        selectedOption ? "" : "opacity-50 cursor-not-allowed"
                      }`}
                      disable={!selectedOption}
                      buttonClick={handlePayment}
                    />
                  </div>
                  <div className="w-full text-center text-[0.9rem]">
                    <span
                      className="cursor-pointer"
                      onClick={() => navigate(-1)}
                    >
                      Back to previous screen
                    </span>
                  </div>
                </div>
              </form>
            ) : (
              <TailSpin
                visible={true}
                height="50"
                width="50"
                color="#024773"
                ariaLabel="tail-spin-loading"
                radius="2"
                strokeWidth="2"
                wrapperStyle={{}}
                wrapperClass=""
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    plans: state.creditPlanReducer,
    updatePlan: state.updateCreditPlanReducer,
    profile: state.getProfileReducer,
  };
};

export default connect(mapStateToProps)(BuyCredit);
