import { useEffect, useState } from "react";
import HomeCard from "../../Component/Home/HomeCard";
import ToggleButtonEvent from "../../Component/Home/ToggleButtonEvent";
import PrevEventList from "../../Component/Home/PrevEventList";
import { useDispatch } from "react-redux";
import {
  getEventRequest,
  getEventReset,
  getProfileInfoRequest,
  validateDateTimeEmpty,
} from "../../Redux/Actions";
import { connect } from "react-redux";
import { homeTabData } from "../../Utils/dummyStorage/data";
import { TailSpin } from "react-loader-spinner";
import { sendInvitationEmpty } from "../../Redux/Actions/invitation/sendInvitationActions";
import { postCreateEventIDEmpty } from "../../Redux/Actions/Form/CreateEventActions";
import { saveAndSendInvitationEmpty } from "../../Redux/Actions/invitation/saveAndSendInvitationActions";

interface EventHomeProps {
  getEventData: any;
  validateTime: any;
}

const EventHome: React.FC<EventHomeProps> = ({
  getEventData,
  validateTime,
}) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  // const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    dispatch(getEventRequest(0, 0, selectedTab === 0 ? "UPCOMING" : "PAST"));
  };
  useEffect(() => {
    fetchData();
    return () => {
      dispatch(getEventReset());
    };
  }, [selectedTab]);

  const getProfileInfo = () => {
    dispatch(getProfileInfoRequest());
  };
  useEffect(() => {
    dispatch(sendInvitationEmpty());
    dispatch(postCreateEventIDEmpty());
    dispatch(sendInvitationEmpty());
    dispatch(saveAndSendInvitationEmpty());
    getProfileInfo();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(validateDateTimeEmpty());
    };
  }, []);

  const eventList =
    getEventData?.data && getEventData?.data?.events
      ? getEventData?.data?.events
      : [];
  return (
    <div className="homeScreen md:w-[80vw] w-full right-0 absolute top-12 text-center overflow-clip">
      <div className="h-full w-full flex flex-col items-center">
        <div className="text-[#024773] text-[1.6rem] font-bold w-full mb-8">
          Events
        </div>
        <HomeCard>
          <div className="w-full h-full">
            <ToggleButtonEvent
              options={homeTabData}
              selectedTab={selectedTab}
              setSelectedTab={(data) => setSelectedTab(data)}
            />
            {getEventData?.loading ? (
              <div className="w-full flex justify-center">
                <TailSpin
                  visible={true}
                  height="50"
                  width="50"
                  color="#024773"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  strokeWidth="2"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <PrevEventList eventList={eventList} selectedTab={selectedTab} />
            )}
          </div>
        </HomeCard>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getEventData: state.getEventReducer,
    validateTime: state.ValidateDateReducer,
  };
};

export default connect(mapStateToProps)(EventHome);
